<template>
  <div class="home-container">
    <img
      src="../assets/img/home/home-img.png"
      alt="Ars Nova Editio"
      class="home-header-img"
    />
    <div class="about_us_infos">
      <!-- <p>{{ vocabulary['ArsNovaEditio'][currentLanguage] }}</p> -->

      <p>
        {{ vocabulary['ANEAbout'][currentLanguage] }}
      </p>
    </div>

    <div class="release-title">
      {{ vocabulary['NewReleases'][currentLanguage] }}
    </div>

    <div class="new_releases_container">
      <div class="new_release" @click="navigateToProduct('AN128')">
        <img
          class="new-release-cover"
          src="../assets/img/covers/cover_an128.png"
          alt="AN124"
        />
        <div class="details">
          <div class="composer">Michele Josia</div>
          <div class="title">Regina Coeli</div>
          <div class="orch">
            for equal voices or mixed choir ad libitum with body percussion
          </div>
        </div>
      </div>

      <div class="new_release" @click="navigateToProduct('AN130')">
        <img
          class="new-release-cover"
          src="../assets/img/covers/cover_an130.png"
          alt="AN124"
        />
        <div class="details">
          <div class="composer">György Orbán</div>
          <div class="title">Gyermek születék</div>
          <div class="orch">SATB, piano</div>
        </div>
      </div>

      <div class="new_release" @click="navigateToProduct('AN129')">
        <img
          class="new-release-cover"
          src="../assets/img/covers/cover_an129.png"
          alt="AN124"
        />
        <div class="details">
          <div class="composer">György Orbán</div>
          <div class="title">Peur Natus</div>
          <div class="orch">SATB, piano</div>
        </div>
      </div>

      <div class="new_release" @click="navigateToProduct('AN127')">
        <img
          class="new-release-cover"
          src="../assets/img/covers/cover_an127.png"
          alt="AN124"
        />
        <div class="details">
          <div class="composer">Battista Pradal</div>
          <div class="title">O lux beata Trinitas</div>
          <div class="orch">SATB</div>
        </div>
      </div>

      <div class="new_release" @click="navigateToProduct('AN126')">
        <img
          class="new-release-cover"
          src="../assets/img/covers/cover_an126.png"
          alt="AN125"
        />
        <div class="details">
          <div class="composer">Battista Pradal</div>
          <div class="title">Seeing the stars</div>
          <div class="orch">SATB</div>
        </div>
      </div>

      <div class="new_release" @click="navigateToProduct('AN123')">
        <img
          class="new-release-cover"
          src="../assets/img/covers/cover_an123.png"
          alt="AN123"
        />
        <div class="details">
          <div class="composer">György Orbán</div>
          <div class="title">Latin Female Choirs</div>
          <div class="orch">SSA</div>
        </div>
      </div>

      <div class="new_release" @click="navigateToProduct('AN125')">
        <img
          class="new-release-cover"
          src="../assets/img/covers/cover_an125.png"
          alt="AN123"
        />
        <div class="details">
          <div class="composer">György Orbán</div>
          <div class="title">Stabat Mater (in D)</div>
          <div class="orch">Vocal score</div>
        </div>
      </div>

      <div class="new_release" @click="navigateToProduct('AN124')">
        <img
          class="new-release-cover"
          src="../assets/img/covers/cover_an124.png"
          alt="AN123"
        />
        <div class="details">
          <div class="composer">György Orbán</div>
          <div class="title">Stabat Mater (in D)</div>
          <div class="orch">Score and instrumental parts</div>
        </div>
      </div>

      <div class="new_release" @click="navigateToProduct('AN122')">
        <img
          class="new-release-cover"
          src="../assets/img/covers/cover_an122.png"
          alt="AN123"
        />
        <div class="details">
          <div class="composer">György Orbán</div>
          <div class="title">Latin Mixed Choirs 2.</div>
          <div class="orch">SATB</div>
        </div>
      </div>

      <div class="new_release" @click="navigateToProduct('AN120')">
        <img
          class="new-release-cover"
          src="../assets/img/covers/cover_an120.png"
          alt="AN123"
        />
        <div class="details">
          <div class="composer">György Orbán</div>
          <div class="title">Latin Mixed Choirs 1.</div>
          <div class="orch">SATB</div>
        </div>
      </div>

      <div class="new_release" @click="navigateToProduct('AN121')">
        <img
          class="new-release-cover"
          src="../assets/img/covers/cover_an121.png"
          alt="AN123"
        />
        <div class="details">
          <div class="composer">Guilio De Carlo</div>
          <div class="title">L'aquilone</div>
          <div class="orch">SA, piano</div>
        </div>
      </div>
    </div>
    <!--     <div class="videos-container">
      <iframe
        class="homepage-video"
        src="https://www.youtube.com/embed/-SUFT96LRMg?si=gHcBrMIhb4vCzpsh"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div> -->
  </div>
</template>

<script>
import vocabulary from '../assets/vocabulary.js';

export default {
  name: "Home",
  setup() {
    return {
      vocabulary,
    }
  },
  components: {},
  methods: {
    navigateToProduct(selectedProductId) {
    this.$router.push({ path: '/publications', query: { id: selectedProductId } });
}
  },
  props: {
    currentLanguage: String,
  },
};
</script>

<style scoped>

.home-container {
  padding-bottom: 2vh;
  border-radius: 2vh;
  background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(167, 167, 167, 0.5)),
                  url("../assets//img/backgorund/catalog-bg.png");
  user-select: none;
}

.about_us_infos {
  text-align: center;
  font-size: 2vh;
  font-style: italic;
}

.home-header-img {
  width: 100%;
  transform: scale(1.12);
  margin-top: 1.5vh;
  filter: saturate(0.7);

}

.release-title {
  background: linear-gradient(75deg, #1b5d8f, transparent);
  padding: 1vh 2vw;
  border-radius: 12vh 0vh 260% 6vh;
  font-size: 2.2vh;
  text-align: left;
  text-transform: uppercase;
  color: var(--primary-2);
}
.new_releases_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  gap: 20px;
  margin: 0 auto;
  margin-top: 1vh;
}

.new_release {
  border-radius: 2vh;
  padding: 2vh 0;
  box-shadow: 6px 2px 10px 2px rgba(0, 0, 0, 0.2);
  transition: 0.4s;
  background: linear-gradient(115deg, var(--primary-4), var(--primary-3));
  display: flex;
}

.new_release:hover {
  cursor: pointer;
  transform: scale(1.05);
  background: linear-gradient(60deg, var(--primary-5), var(--primary-2));
}

.new-release-cover {
  width: 148px;
  height: 210px;
  margin: 0.3vh 0.7vw;
  border-radius: 0.5vh;
  transform: skew(8deg, 352deg);
}

.details {
  display: block;
  text-align: center;
  padding-top: 5vh;
}

.composer {
  font-weight: bold;
}

.title {
  border-radius: 2vh;
}
.videos-container {
margin-top: 5vh;
width: 90vw;
display: flex;
justify-content: center;
}

.homepage-video {
  width: 560px;
  height: 315px;
  border-radius: 2vh;
}

.orch {
  font-size: 1.5vh;
}
</style>
