const vocabulary = {
  // Header
  Composers: {
    english: 'Composers',
    hungarian: 'Zeneszerzők',
  },
  Catalog: {
    english: 'Catalog',
    hungarian: 'Kiadványaink',
  },
  Contact: {
    english: 'Contact',
    hungarian: 'Kapcsolat',
  },
  // Home
  ArsNovaEditio: {
    english: 'Ars Nova Editio Music Publisher Ltd., Hungary',
    hungarian: 'Ars Nova Editio Zeneműkiadó Kft.',
  },
  ANEAbout: {
    english:
      'Ars Nova Editio is one of the most significant publishers in Hungarian contemporary choral music since 1994.',
    hungarian:
      'Az Ars Nova Editio a magyar kortárs kóruszene egyik meghatározó jelentőségű kiadója 1994 óta.',
  },
  NewReleases: {
    english: 'New releases',
    hungarian: 'Újdonságok',
  },

  // Publications
  BrowseCatalog: {
    english: 'Open Catalog',
    hungarian: 'Katalógus Megnyitása',
  },
  AllComposers: {
    english: 'All Composers',
    hungarian: 'Összes zeneszerző',
  },
  Orchestration: {
    english: 'Orchestration',
    hungarian: 'Hangszerelés',
  },
  SearchForTitle: {
    english: 'Search for Title',
    hungarian: 'Keresés címre',
  },
  DigitalDownload: {
    english: 'Digital Download',
    hungarian: 'Digitális kiadvány',
  },
  PrintedMusic: {
    english: 'Printed Music',
    hungarian: 'Nyomtatott kiadvány',
  },
  Preview: {
    english: 'Preview',
    hungarian: 'Megtekintés',
  },
  euro: {
    english: '€',
    hungarian: 'Ft',
  },
  MinimumOrder: {
    english: 'The minimum order is 20.',
    hungarian: 'A minimális rendelésszám 20 darab.',
  },
  Shipping: {
    english:
      'Shipping for printed orders will be calculated. If you would like to place an order from our catalog, please send us an email to ',
    hungarian:
      'A nyomtatott rendelések szállítási költsége külön kerül kiszámításra. Ha szeretne rendelést leadni a katalógusunkból, kérjük, küldjön nekünk egy e-mailt a következő címre: ',
  },
  Skill: {
    english: 'Skill level',
    hungarian: 'Nehézségi szint',
  },
  Pages: {
    english: 'Pages',
    hungarian: 'Oldalszám',
  },
  Duration: {
    english: 'Duration',
    hungarian: 'Időtartam',
  },
  Published: {
    english: 'Published',
    hungarian: 'Kiadva',
  },

  // Contact
  ContactPart1: {
    english:
      'If you would like to order products from our catalog, please send us an email to the following address:',
    hungarian:
      'Amennyiben szeretne terméket rendelni a katalógusunkból, kérjük, küldjön nekünk egy e-mailt a következő címre:',
  },
  ContactPart2: {
    english:
      'In the email, specify the products and quantities you would like to order. The minimum order quantity is 20 pieces. We will send you a price quote shortly.',
    hungarian:
      'Az e-mailben pontosítsa, mely termékeket és mennyiségeket szeretné megrendelni. A minimum rendelési mennyiség 20 darab. Hamarosan elküldjük az árajánlatot Önnek.',
  },
  ContactPart3: {
    english:
      'If you have any questions, feel free to reach out to us at the email address ',
    hungarian:
      'Ha kérdése merül fel, bátran forduljon hozzánk az alábbi e-mail címen: ',
  },
  MailingAdress: {
    english: 'Mailing address:',
    hungarian: 'Levelezési cím:',
  },
  BankDetails: {
    english: 'Bank transfer details:',
    hungarian: 'Banki átutalási adatok:',
  },
  BankName: {
    english: 'Bank name:',
    hungarian: 'Bank neve:',
  },
  AccountNumber: {
    english: 'Account number:',
    hungarian: 'Számlaszám:',
  },
  Hungary: {
    english: 'Hungary',
    hungarian: 'Magyarország',
  },
};

export default vocabulary;
