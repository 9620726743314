<template>
  <div
    v-if="selectedProduct = selectedProduct == undefined ? '' : selectedProduct"
    class="product-container"
  >
    <div class="product-header">
      <div class="cover-img-container" @click="previewIsOpen = true">
        <img :src="selectedProduct.img" alt="Cover" class="cover-img" />
        <div>{{ vocabulary['Preview'][currentLanguage] }}</div>
      </div>

      <div class="infos-container">
        <div class="product-title">{{ selectedProduct.composer }}</div>
        <div class="product-title" style="font-weight: bold;">
          {{ selectedProduct.title }}
        </div>
        <div class="product-id">{{ id }}</div>

        <div class="prices-container">
          <div class="product-price-row">
            <div class="price-title">
              {{ vocabulary['DigitalDownload'][currentLanguage] }} (pdf):
            </div>
            <div class="product-info">
              {{ calculateDigitalPrice() }}
              {{ vocabulary['euro'][currentLanguage] }}
            </div>
          </div>
          <div class="product-price-row">
            <div class="price-title">
              {{ vocabulary['PrintedMusic'][currentLanguage] }}:
            </div>

            <div class="product-info">
              {{ calculatePrintedPrice() }}
              {{ vocabulary['euro'][currentLanguage] }}
            </div>
          </div>
          <div class="info-bg">
            <div class="shipping-infos">
              {{ vocabulary['Shipping'][currentLanguage] }}
              <a href="mailto:info.arsnovaeditio@gmail.com"
                >info.arsnovaeditio@gmail.com</a
              >
            </div>
            <span style="font-style: italic;">
              {{ vocabulary['MinimumOrder'][currentLanguage] }}</span
            >
          </div>
        </div>

        <div class="product-infos">
          <div class="product-info-row">
            <div class="product-info-title">
              {{ vocabulary['Orchestration'][currentLanguage] }}:
            </div>
            <div class="product-info">{{ selectedProduct.orch }}</div>
          </div>

          <div class="product-info-row">
            <div class="product-info-title">
              {{ vocabulary['Skill'][currentLanguage] }}:
            </div>
            <div class="product-info">{{ selectedProduct.skill }}</div>
          </div>

          <div class="product-info-row">
            <div class="product-info-title">
              {{ vocabulary['Pages'][currentLanguage] }}:
            </div>
            <div class="product-info">{{ selectedProduct.pages }}</div>
          </div>

          <div class="product-info-row">
            <div class="product-info-title">
              {{ vocabulary['Duration'][currentLanguage] }}:
            </div>
            <div class="product-info">{{ selectedProduct.duration }}</div>
          </div>

          <div class="product-info-row">
            <div class="product-info-title">
              {{ vocabulary['Published'][currentLanguage] }}:
            </div>
            <div class="product-info">{{ selectedProduct.published }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="box-video" v-if="videoIsAvailable">
    <iframe
      class="video-player"
      :src="checkingVideo()"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
  </div>
  <div v-if="previewIsOpen" class="image-preview-container">
    <div class="close-button-container" @click="previewIsOpen = false">
      <CloseButton class="close-button" />
    </div>

    <div class="preview-gallery-container">
      <img
        :src="currentImage"
        alt="Product Preview"
        class="current-preview-image"
      />
      <div class="preview-nav-button" @click="changeImage()">
        <NextIcon class="next-icon" />
      </div>
    </div>
  </div>
</template>

<script>
import {products} from '../db/products'
import vocabulary from '../assets/vocabulary.js';
import NextIcon from '../assets/icons/NextIcon.vue'
import CloseButton from '../assets/icons/CloseButton.vue'
export default {
  data() {
    return {
      products,
      vocabulary,
      selectedProduct: null,
      videoIsAvailable: true,
      cartItems: new Set(),
      inputValueDigital: 0,
      inputValuePrinted: 0,
      previewIsOpen: false,
      currentImageIndex: 0,

    };
  },
  name: "Product",
  props: {
    id: String,
    currentLanguage: String,
  },
  components: {NextIcon, CloseButton},
  watch: {
    id(newId) {
      this.selectedProduct = this.products.find(product => product.id === newId);

      this.checkingVideo()
    },
  },
  created() {
    this.selectedProduct = this.products.find(product => product.id === this.id);
  },
  computed: {
    currentImage() {
      return this.imagePaths[this.currentImageIndex];
    },
    imagePaths() {
      const imageArray = [];
      imageArray.push(this.selectedProduct.img)
      for (let i = 1; i <= 6; i++) {
        const previewImage = this.selectedProduct[`previewImage0${i}`];
        if (previewImage !== undefined) {
          imageArray.push(previewImage);
      }
    }
      return imageArray;
    }
  },
  methods: {

    changeImage() {
      this.currentImageIndex = (this.currentImageIndex + 1) % this.imagePaths.length;
    },
    addToCart() {
      this.cartItems.add(this.selectedProduct);
    },

    checkingVideo() {
      if (this.selectedProduct.video == '') {
        this.videoIsAvailable = false
      } else {
        this.videoIsAvailable = true
        return this.selectedProduct.video
      }
    },

    calculatePrintedPrice() {
      if (this.currentLanguage == 'english') {
        return this.selectedProduct.price_printed
      } else if (this.currentLanguage == 'hungarian') {
        return Math.floor(this.selectedProduct.price_printed * 350)
      }
    },

    calculateDigitalPrice() {
      if (this.currentLanguage == 'english') {
        return this.selectedProduct.price_digital
      } else if (this.currentLanguage == 'hungarian') {
        return Math.floor(this.selectedProduct.price_digital * 350)
      }
    }
  },


};
</script>

<style scoped>


.product-container {
  width: 85vw;
  margin-left: 2vw;
  padding: 2vh 2vw;
}

.info-bg {
  background: linear-gradient(to left, transparent 35%, #f1f1f1);
  border-radius: 15px;
  width: 80%;
}

.shipping-infos {
}


/* Mobile view */
@media screen and (max-width: 767px) {
  .cover-img {
    width: 89px;
    height: 126px;
  }
  .product-title {
    font-size: 2vh;
  }
  .price-title {
  width: 35vw;
  font-weight: bold;
}
.box-video {
margin: 2vh auto;
}
.video-player {
  width: 75vw;
  height: 28vh;
  border-radius: 2vh;
}
.shipping-infos {
  margin-top: 30px;
  width: 100%;
}
.current-preview-image {
  max-width: 90%;
  height: auto;
}
}

/* Desktop view */
@media screen and (min-width: 768px) {
.cover-img {
    width: 267px;
    height: 379px;
  }
.product-title {
    font-size: 2.5vh;
    margin-bottom: 1vh;
  }
.price-title {
  width: 9vw;
}
.box-video {
 position: absolute;
 top: 7.5vh;
 right: 7vw;
}
.video-player {
  width: 30vw;
  height: 35vh;
  border-radius: 2vh;
}
.shipping-infos {
  margin-top: 30px;
  width: 36%;
}
.current-preview-image {
  width: auto;
  max-height: 70vh;
}
}

/* Header (cover + iportant infos) */
.product-header {
  display: flex;
}

.cover-img-container {
  cursor: pointer;
}
.cover-img {
  box-shadow: 4px 4px 5px 4px var(--primary-5-opa);
  transition: 0.4s;
}

.cover-img:hover {
  transform: scale(1.01);
}

.infos-container {
  margin-left: 2vw;
}

.prices-container {
  margin-top: 5vh;
  font-size: 1.8vh;
  width: 100%;
}
.product-price-row {
  display: flex;
  align-items: center;
  margin: 1vh 0;
}
.product-info-row {
    display: flex;
}


.product-infos {
  margin-top: 3vh;
}

.product-info {
  margin-left: 1vw;
  font-weight: 500;
}

/* Preview image */
.image-preview-container {
  position: absolute;
  width: 95vw;
  height: 87vh;
  top: 0;
  left: 2.5vw;
  z-index: 2;
  border-radius: 2vh;
  background-color: #000000d9;
}
.close-button-container {
  margin-left: auto;
  display: flex;
  justify-content: right;
  padding-right: 2vw;
  padding-top: 3vh;
}

.close-button {
  color: var(--primary-2);
  width: 45px;
  height: auto;
  transition: 0.4s;
}

.close-button:hover {
  cursor: pointer;
  color: var(--primary-3);
}

.preview-gallery-container {
  display: flex;
  align-items: center;
  padding: 0 2vw;
  height: 77vh;
}

.preview-nav-button {
  color: var(--primary-2);
  width: 100%;
  display: flex;
  justify-content: center;
}

.preview-nav-button:hover {
  cursor: pointer;
  color: var(--primary-3);
}

.current-preview-image {
  user-drag: none; /* Safari */
  user-select: none; /* Older webkit browsers */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  border-radius: 2vh;
}

.next-icon {
  width: 5vw;
}
</style>
