<template>
  <div class="container_composers">
    <div class="container_biography">
      <div>
        <img :src="composers[currentid].img" alt="" class="profile_pic" />
        <a
          class="website-link"
          :href="composers[currentid].website"
          target="_blank"
          >Website</a
        >
      </div>
      <div class="bio_container">
        <div class="bio_name">{{ composers[currentid].composer }}</div>
        <div v-if="currentLanguage == 'hungarian'" class="bio_content">
          {{ composers[currentid].bioHun }}
        </div>
        <div v-if="currentLanguage == 'english'" class="bio_content">
          {{ composers[currentid]?.bioEng }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {composers} from '../db/composers'
export default {
  data() {
    return {
      composers
    };
  },
  name: "Composers",
  props: ["currentid", "currentLanguage"]
};
</script>

<style scoped>
.container_biography {
  margin-top: 4vh;
  height: 68vh;
  overflow: auto;
  background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(167, 167, 167, 0.5)),
                  url("../assets//img/backgorund/catalog-bg.png");
  display: flex;
  font-size: 1.8vh;
  background-color: var(--primary-2);
  border-radius: 2vh;
  padding: 2vh 2vw;
  box-shadow: 1px 2px 6px 0px var(--primary-5-opa);

}
.profile_pic {
  border-radius: 2vh;
  width: 20vw;
}
.bio_container {
  margin: 0 0 0 2vw;
  padding: 0 0 0 2vw;
}
.bio_name {
  font-size: 2vh;
  margin-bottom: 1vh;
}

.website-link {
  color: var(--important);
  display: flex;
  justify-content: center;
}
</style>
