<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    viewBox="0 0 512 512"
  >
    <g id="Group_1" data-name="Group 1" transform="translate(-413 -194)">
      <rect
        id="Rectangle_1"
        data-name="Rectangle 1"
        width="512"
        height="512"
        transform="translate(413 194)"
        fill="none"
      />
      <path
        id="Path_1"
        data-name="Path 1"
        d="M258,2.04C117.2,2.04,2,116.984,2,258.552c0,128,93.7,234.24,216.064,253.44v-179.2H153.04v-74.24h65.024V201.976c0-64.256,38.144-99.584,96.768-99.584,27.9,0,57.088,4.864,57.088,4.864v63.232H339.664c-31.744,0-41.728,19.712-41.728,39.936v48.128H369.1l-11.52,74.24H297.936v179.2A256,256,0,0,0,514,258.552C514,116.984,398.8,2.04,258,2.04Z"
        transform="translate(411 192.96)"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
