<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    viewBox="0 0 512 512"
  >
    <g id="Group_1" data-name="Group 1" transform="translate(143 -145)">
      <path
        id="Path_1"
        data-name="Path 1"
        d="M78.9,450.3l83.8-49.2L78.9,351.9Z"
        fill="currentColor"
      />
      <path
        id="Path_2"
        data-name="Path 2"
        d="M113,145c-141.4,0-256,114.6-256,256S-28.4,657,113,657,369,542.4,369,401,254.4,145,113,145ZM241,446.8h0c0,44.1-44.1,44.1-44.1,44.1H29.1C-15,490.9-15,446.8-15,446.8V355.3c0-44.1,44.1-44.1,44.1-44.1H196.9c44.1,0,44.1,44.1,44.1,44.1Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
