<template>
  <div class="header_container">
    <MusicPlayer />
    <div class="header_toprow">
      <div class="ANE_logo">
        <router-link
          to="/"
          :currentLanguage="currentLanguage"
          @changeLanguage="changeLanguage"
          ><Logo class="logo"
        /></router-link>
      </div>

      <div class="social_container">
        <a href="https://www.youtube.com/@arsnovaeditio4667" target="_blank">
          <YoutubeLogo class="topnav_icon" />
        </a>
        <a href="https://www.facebook.com/arsnovaeditio/" target="_blank">
          <FacebookLogo class="topnav_icon" />
        </a>

        <img
          src="../assets/img/topnav/uk.png"
          alt="English"
          class="topnav_icon"
          @click="changeLanguage('english')"
        />
        <img
          src="../assets/img/topnav/hungary.png"
          alt="Hungarian"
          class="topnav_icon"
          @click="changeLanguage('hungarian')"
        />
      </div>
    </div>

    <div class="navbar">
      <span
        ><router-link
          to="/composers"
          class="navbutton"
          :currentLanguage="currentLanguage"
          @changeLanguage="changeLanguage"
          >{{ vocabulary['Composers'][currentLanguage] }}</router-link
        ></span
      >
      <span
        ><router-link
          to="/publications"
          class="navbutton"
          :currentLanguage="currentLanguage"
          @changeLanguage="changeLanguage"
          >{{ vocabulary['Catalog'][currentLanguage] }}</router-link
        ></span
      >
      <span
        ><router-link
          to="/contact"
          class="navbutton"
          :currentLanguage="currentLanguage"
          @changeLanguage="changeLanguage"
          >{{ vocabulary['Contact'][currentLanguage] }}</router-link
        ></span
      >
    </div>
  </div>
</template>

<script>
import Logo from "@/assets/icons/ANE_logo";
import YoutubeLogo from '../assets/img/topnav/youtube.vue';
import FacebookLogo from '../assets/img/topnav/facebook.vue';
import vocabulary from '../assets/vocabulary.js'

export default {
  data() {
    return {
      vocabulary,
}
  },
  components: {
    Logo,
    YoutubeLogo,
    FacebookLogo,
  },
  props: {
    currentLanguage: {
      type: String,
      default: 'english'
    },
  },
  methods: {
    changeLanguage(newLanguage) {
      this.$emit('changeLanguage', newLanguage);
    },
  },
};
</script>

<style scoped>
.header_container {
  width: 100vw;
  padding-top: 2.5vh;
  position: fixed;
  top: 0;
  z-index: 2;
  user-select: none;
  background: linear-gradient(125deg, #2e5777, #1f3749);

}

.header_toprow {
  display: flex;
  font-size: 2vh;
}

.ANE_logo {
  display: flex;
  justify-content: center;
  margin-left: 5vw;
}

.logo {
  height: 7.5vh;
  width: auto;
  color: var(--primary-2);
  transition: 0.6s;
}
.logo:hover {
  color: var(--primary-3);
}

.social_container {
  margin-left: auto;
  margin-right: 5vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 170px;
}

.topnav_icon {
  color: var(--primary-3);
}

.topnav_icon:hover {
  color: var(--primary-2);
}

.navbar {
  display: flex;
  justify-content: space-evenly;
  margin: auto;
  margin-top: 1vh;
}

.navbutton {
  font-size: 2.3vh;
  width: 16vw;
  text-align: center;
  font-weight: 500;
  transition: 0.3s;
  text-decoration: none;
  color: var(--primary-2);
  padding: 1vh 1vw;
  text-transform: uppercase;
}


.navbutton:hover {
  cursor: pointer;
  color: var(--primary-3);
}
</style>
