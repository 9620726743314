<template>
  <div class="webshop-container">
    <div
      class="publications-welcome-container"
      v-if="selectedProductId == undefined && !sidebarIsOpen"
    >
      <div class="link-to-catalog" @click="sidebarIsOpen = !sidebarIsOpen">
        <div class="welcome-image-container">
          <img
            src="../assets/img/home/catalog-ane.png"
            class="welcome-image"
            alt="Browse Catalog"
          />
        </div>
        <div class="welcome-title">
          {{ vocabulary['BrowseCatalog'][currentLanguage] }}
        </div>
      </div>
    </div>
    <div class="selection-container" v-if="sidebarIsOpen">
      <div class="filter-section">
        <label for="composer-select"></label>
        <select
          v-model="selectedComposer"
          id="composer-select"
          class="composer-select"
        >
          <option value="">
            {{ vocabulary['AllComposers'][currentLanguage] }}
          </option>
          <option
            v-for="composer in uniqueComposers"
            :value="composer"
            :key="composer"
          >
            {{ composer }}
          </option>
        </select>
      </div>

      <div class="title-filter">
        <input
          v-model="searchQuery"
          type="text"
          :placeholder="vocabulary['SearchForTitle'][currentLanguage]"
          class="filter-input"
        />
      </div>

      <div class="filter-section">
        <label for="orchestration-select"></label>
        <select
          v-model="selectedOrchestration"
          id="orchestration-select"
          class="orchestration-select"
        >
          <option value="">
            {{ vocabulary['Orchestration'][currentLanguage] }}
          </option>
          <option
            v-for="orchestration in uniqueOrchestrations"
            :value="orchestration"
            :key="orchestration"
          >
            {{ orchestration }}
          </option>
        </select>
      </div>
    </div>

    <div class="container">
      <div class="sidebar" v-if="sidebarIsOpen">
        <div class="composer-title">{{ composerTitle }}</div>

        <div
          v-for="product in filteredProducts"
          :key="product.id"
          class="product-card"
          @click="openProduct(product.id)"
        >
          <p v-if="composerTitle == 'All Publications'" class="list-composer">
            {{ product.composer }}:
          </p>
          <p class="list-title">
            {{ product.title }}
            <span style="font-style: italic; font-size: x-small;"
              >/ {{ product.orch }} /</span
            >
          </p>
        </div>
      </div>

      <div
        @click="sidebarIsOpen = !sidebarIsOpen"
        v-if="selectedProductId != undefined && !sidebarIsOpen"
      >
        <BackIcon class="back-icon" />
      </div>

      <!-- Product Page -->
      <div v-if="productIsSelected && !sidebarIsOpen">
        <Product
          :id="selectedProductId"
          :currentLanguage="currentLanguage"
          @changeLanguage="changeLanguage"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Product from '../components/Product.vue';
import BackIcon from '../assets/icons/BackIcon.vue'
import {products} from '../db/products'
import vocabulary from '../assets/vocabulary.js';


export default {
  data() {
    return {
      products,
      productIsSelected: false,
      cartIsOpen: false,
      selectedComposer: '',
      selectedOrchestration: '',
      searchQuery: "",
      sidebarIsOpen: true,
      vocabulary
    };
  },
  components: {
    Product, BackIcon
  },
  props: {
    currentLanguage: String,
  },

  computed: {
    uniqueComposers() {
      const composers = new Set();
      this.products.forEach(product => {
        composers.add(product.composer);
      });
      return Array.from(composers);
    },

    uniqueOrchestrations() {
      const orchestrations = new Set();
      this.products.forEach(product => {
        orchestrations.add(product.type);
      });
      return Array.from(orchestrations);
    },

    filteredProducts() {
      let filtered = this.products;

      if (this.selectedComposer !== '') {
        filtered = filtered.filter(product => product.composer === this.selectedComposer);
      }

      if (this.selectedOrchestration !== '') {
        filtered = filtered.filter(product => product.type === this.selectedOrchestration);
      }

      if (this.searchQuery !== '') {
        filtered = filtered.filter(product =>
        product.title.toLowerCase().includes(this.searchQuery.toLowerCase()));
      }

      // Sort products by title alphabetically
      filtered.sort((a, b) => a.title.localeCompare(b.title));

      return filtered;
    },

    composerTitle() {
      if (this.selectedComposer === '') {
          return 'All Publications';
      } else {
        return this.selectedComposer;
      }
    },

  },

  methods: {
    openProduct(id) {
      this.selectedProductId = id;
      this.productIsSelected = true;
      this.sidebarIsOpen = false;
    },
    switchSidebar() {
      this.sidebarIsOpen = false;
    },
    setUpCatalog() {
      this.selectedProductId = 'AN098'
      this.sidebarIsOpen = false;
    }
  },
  beforeMount() {
    this.setUpCatalog();
    this.selectedProductId = this.$route.query.id;
    this.openProduct(this.selectedProductId)
}

};
</script>

<style>
.webshop-container {
  margin-top: 1.5vh;
  color: var(--primary-1);
  background-color: var(--primary-2);
  border-radius: 2vh 2vh 0 0;
  min-height: 81vh;
  background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(167, 167, 167, 0.5)),
                  url("../assets//img/backgorund/catalog-bg.png");
}

/* Welcome page */
.welcome-title {
  padding: 2vh 0 0.5vh 0;
  font-size: 3vh;
  text-align: center;
  text-transform: uppercase;
}

.link-to-catalog {
  border-radius: 2vh;
  animation: flo 3s;
}

.link-to-catalog:hover {
  cursor: pointer;
  filter: saturate(0.4);
}

.welcome-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.welcome-image {
  width: 95%;
  opacity: 0.8;
  transition: 0.5s;
  border-radius: 30px;
  animation: flo 1s;
}

.welcome-image:hover {
  opacity: 1;
  width: 96%;
}


@keyframes flo{
0%{
  filter: saturate(0);

}
  50%{
    filter: saturate(0.4);
  }
  100%{
    filter: saturate(1);

  }
}

.list-composer {
  margin-right: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.list-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}



/* Filter */
.selection-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2vw;
  box-sizing: border-box;
  height: 7vh;
  width: 100%;
  background-color: var(--primary-5);
  border-radius: 2vh 2vh 0 0;
}

.container {
  display: flex;
}

.filter-section {
  width: 25vw;
}

select {
  height: 4vh;
  width: 100%;
  border-radius: 2vh;
  padding: 5px;
  font-size: 1.8vh;
  border: 2px solid var(--primary-3);
  background-color: var(--primary-3);
}


option:nth-child(even) {
    background-color: var(--primary-2);
  }

.filter-input {
  border-radius: 2vh;
  height: 3.5vh;
  width: 28vw;
  padding: 0 1vw;
  font-size: 1.8vh;
  border: 2px solid var(--primary-3);
  background-color: var(--primary-3);
}


/* Sidebar */
.sidebar {
  box-shadow: 6px 2px 10px 2px rgba(0, 0, 0, 0.2);
  width: 96%;
  min-height: 74vh;
  padding: 0 2vw;
  background-image: linear-gradient(rgba(240, 240, 240, 0.5), rgba(116, 116, 116, 0.5)),
                  url("../assets//img/backgorund/catalog-bg.png");

}

.composer-title {
  font-size: 2.5vh;
  font-weight: bold;
  margin: 2vh 0;
  color: var(--primary-1);
}

.product-card {
  display: flex;
  font-size: 2.2vh;
  height: 2.5vh;
  width: 95%;
  align-items: center;
  margin: 2vh 0;
}

.product-card:hover {
  cursor: pointer ;
  color: var(--primary-1);
  font-weight: bold;
}

/* Products */
.back-icon {
  height: 20px;
  width: auto;
  margin: 1.5vh 0 0 2vw;
  transition: 0.4s;
}
.back-icon:hover {
  cursor: pointer;
  color: var(--primary-5);
  transform: scale(1.3);
}
</style>
