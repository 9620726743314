<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="365.246"
    height="110.219"
    viewBox="0 0 365.246 110.219"
  >
    <g id="Layer_2" data-name="Layer 2" transform="translate(0 0.04)">
      <g id="Layer_1" data-name="Layer 1">
        <path
          id="Path_1"
          data-name="Path 1"
          d="M0,61,21.75,1.25h3.73L47.23,61H43.49L22.33,2.82h2.49L3.73,61ZM8.3,45.48V42.16H38.84v3.32Z"
          fill="currentColor"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M52.68,61V1.25h22c.58,0,1.18,0,1.79.06a17.57,17.57,0,0,1,2,.27A15.84,15.84,0,0,1,86,4.71a16.42,16.42,0,0,1,4.9,6.23,19.59,19.59,0,0,1,1.72,8.23,18.58,18.58,0,0,1-3.49,11.21,15.38,15.38,0,0,1-9.46,6.14L79,37.1H56.16V61Zm3.48-27.38H74.67q.7,0,1.68-.09c.65-.05,1.31-.14,2-.25a11.85,11.85,0,0,0,5.79-2.74,13.17,13.17,0,0,0,3.55-5,16.74,16.74,0,0,0,1.2-6.33,16.74,16.74,0,0,0-1.2-6.33,13.33,13.33,0,0,0-3.55-5,12,12,0,0,0-5.79-2.74,13.5,13.5,0,0,0-2-.27q-1-.06-1.68-.06H56.16ZM90.11,61,78.32,36.69l3-2.24L94.26,61Z"
          fill="currentColor"
        />
        <path
          id="Path_3"
          data-name="Path 3"
          d="M121.21,62.25a28.73,28.73,0,0,1-10.9-2,21.24,21.24,0,0,1-8-5.58,18.87,18.87,0,0,1-4.25-8.55l3.48-.67a16.45,16.45,0,0,0,6.89,9.72,22.36,22.36,0,0,0,12.79,3.56,23.18,23.18,0,0,0,9.13-1.66,13.87,13.87,0,0,0,6-4.66,11.78,11.78,0,0,0,2.16-7,10.32,10.32,0,0,0-1.12-5.06,9.84,9.84,0,0,0-2.84-3.3A16.21,16.21,0,0,0,131,35c-1.25-.5-2.35-.89-3.32-1.17l-14.94-4.48a24.91,24.91,0,0,1-5.58-2.32A14.83,14.83,0,0,1,103.32,24a11.82,11.82,0,0,1-2.22-3.82,13.26,13.26,0,0,1-.73-4.44,13.92,13.92,0,0,1,2.47-8.12A16.58,16.58,0,0,1,109.71,2a24.22,24.22,0,0,1,10.17-2,24.64,24.64,0,0,1,10.5,2.16A19.81,19.81,0,0,1,138,8.24a19.48,19.48,0,0,1,3.72,9.19l-3.49.66a16,16,0,0,0-3.07-7.65,16.7,16.7,0,0,0-6.43-5.13,20.77,20.77,0,0,0-8.84-1.82,19.83,19.83,0,0,0-8.24,1.61,13.74,13.74,0,0,0-5.54,4.36,10.2,10.2,0,0,0-2,6.14A8.18,8.18,0,0,0,106,21.31a13.07,13.07,0,0,0,4.71,3.32,48.819,48.819,0,0,0,5.31,1.93l12.37,3.73c1,.31,2.33.76,3.86,1.35a19.36,19.36,0,0,1,4.5,2.56,13.13,13.13,0,0,1,3.69,4.37A14.26,14.26,0,0,1,142,45.4a16.25,16.25,0,0,1-1.46,7,14.6,14.6,0,0,1-4.19,5.31,19.5,19.5,0,0,1-6.56,3.39,28.72,28.72,0,0,1-8.58,1.15Z"
          fill="currentColor"
        />
        <path
          id="Path_4"
          data-name="Path 4"
          d="M170.81,61V1.25h3.49l36.35,53.53V1.25h3.49V61h-3.49L174.3,7.39V61Z"
          fill="currentColor"
        />
        <path
          id="Path_5"
          data-name="Path 5"
          d="M247.23,62.25q-8.92,0-14.88-4a24.4,24.4,0,0,1-8.94-11,40.94,40.94,0,0,1-3-16.14,41,41,0,0,1,3-16.15,24.4,24.4,0,0,1,8.94-11q6-4,14.88-4t14.88,4a24.4,24.4,0,0,1,8.94,11,41,41,0,0,1,3,16.15,40.939,40.939,0,0,1-3,16.14,24.4,24.4,0,0,1-8.94,11Q256.15,62.25,247.23,62.25Zm0-3.49q7.68,0,12.8-3.54a21.64,21.64,0,0,0,7.7-9.78,37.129,37.129,0,0,0,2.57-14.31,37.28,37.28,0,0,0-2.57-14.34A21.48,21.48,0,0,0,260,7a22.1,22.1,0,0,0-12.8-3.52q-7.67,0-12.73,3.52a21.61,21.61,0,0,0-7.66,9.78,38.419,38.419,0,0,0-2.66,14.34,36.41,36.41,0,0,0,2.49,14.31,21.59,21.59,0,0,0,7.74,9.78A22.23,22.23,0,0,0,247.23,58.76Z"
          fill="currentColor"
        />
        <path
          id="Path_6"
          data-name="Path 6"
          d="M299.18,61,277.43,1.25h3.74L301,55.94,320.92,1.25h3.74L302.91,61Z"
          fill="currentColor"
        />
        <path
          id="Path_7"
          data-name="Path 7"
          d="M317.9,61,339.65,1.25h3.73L365.13,61H361.4L340.23,2.82h2.49L321.64,61Zm8.3-15.52V42.16h30.55v3.32Z"
          fill="currentColor"
        />
        <path
          id="Path_8"
          data-name="Path 8"
          d="M217.85,109.92a14.91,14.91,0,0,1-8-2.1,13.8,13.8,0,0,1-5.2-6,21.71,21.71,0,0,1-1.83-9.27,21.93,21.93,0,0,1,1.81-9.31,13.56,13.56,0,0,1,5.19-6,15,15,0,0,1,8.07-2.09,14.69,14.69,0,0,1,8.08,2.14,13.52,13.52,0,0,1,5.14,6.19,24.19,24.19,0,0,1,1.78,9.79h-2.74v-.86q-.18-7.09-3.34-10.9t-8.92-3.8q-5.86,0-9.09,3.89t-3.24,10.94q0,7,3.24,10.93t9.09,3.89a13.24,13.24,0,0,0,7.16-1.92,13.44,13.44,0,0,0,4.91-5.52l2.14,1.22a15.06,15.06,0,0,1-5.7,6.49,15.78,15.78,0,0,1-8.55,2.29ZM204.43,93.27V90.71h26.84v2.56Z"
          fill="currentColor"
          stroke="currentColor"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <path
          id="Path_9"
          data-name="Path 9"
          d="M256.3,109.92a13.07,13.07,0,0,1-7.85-2.3,14.3,14.3,0,0,1-4.84-6.26,24.69,24.69,0,0,1,0-17.67,14.48,14.48,0,0,1,4.71-6.24,12.29,12.29,0,0,1,7.52-2.3,13.08,13.08,0,0,1,7.76,2.24,13.83,13.83,0,0,1,4.78,6.16,25.42,25.42,0,0,1,0,17.78,14.5,14.5,0,0,1-4.65,6.27,11.85,11.85,0,0,1-7.43,2.32Zm0-2.56a10.52,10.52,0,0,0,6.48-2,11.9,11.9,0,0,0,3.92-5.33,20.9,20.9,0,0,0,1.3-7.56,20.5,20.5,0,0,0-1.33-7.64,11.51,11.51,0,0,0-3.93-5.23,10.71,10.71,0,0,0-6.45-1.89,10.53,10.53,0,0,0-6.51,1.94A11.4,11.4,0,0,0,246,84.92a21.54,21.54,0,0,0-1.25,7.55,20.9,20.9,0,0,0,1.31,7.61,11.92,11.92,0,0,0,3.89,5.33,10.29,10.29,0,0,0,6.35,1.95ZM268,109V83.08h-.18v-18h2.56V109Z"
          fill="currentColor"
          stroke="currentColor"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <path
          id="Path_10"
          data-name="Path 10"
          d="M279.77,69V65.08h2.56V69Zm0,40V76.06h2.56V109Z"
          fill="currentColor"
          stroke="currentColor"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <path
          id="Path_11"
          data-name="Path 11"
          d="M292.05,78.63V76.06h20.68v2.57ZM312.73,109a17.66,17.66,0,0,1-4.88.42,10.16,10.16,0,0,1-4.3-1.16,6.46,6.46,0,0,1-2.84-3.1,9.261,9.261,0,0,1-.82-3.28c-.06-1.1-.09-2.37-.09-3.79l-.39-37.69,3.26.25-.31,37.44c0,1.42,0,2.57.05,3.44A6,6,0,0,0,303,104a4.8,4.8,0,0,0,3.48,2.7,17.15,17.15,0,0,0,6.22-.14Z"
          fill="currentColor"
          stroke="currentColor"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <path
          id="Path_12"
          data-name="Path 12"
          d="M322.67,69V65.08h2.56V69Zm0,40V76.06h2.56V109Z"
          fill="currentColor"
          stroke="currentColor"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
        <path
          id="Path_13"
          data-name="Path 13"
          d="M349.85,109.92a14.5,14.5,0,0,1-8.23-2.23,13.91,13.91,0,0,1-5.1-6.16,22.19,22.19,0,0,1-1.73-9.06,21.88,21.88,0,0,1,1.76-9.09,13.79,13.79,0,0,1,5.14-6.06,16.5,16.5,0,0,1,16.43,0,13.87,13.87,0,0,1,5.08,6.12,24.61,24.61,0,0,1,0,18.13,13.73,13.73,0,0,1-5.11,6.11,14.68,14.68,0,0,1-8.24,2.24Zm0-2.56q6.22,0,9.27-4.13t3.05-10.76q0-6.73-3.06-10.75t-9.26-4a11.88,11.88,0,0,0-6.91,1.89,11.34,11.34,0,0,0-4.07,5.22,20.441,20.441,0,0,0-1.34,7.65q0,6.72,3.13,10.8T349.85,107.36Z"
          fill="currentColor"
          stroke="currentColor"
          stroke-miterlimit="10"
          stroke-width="0.5"
        />
      </g>
    </g>
  </svg>
</template>
