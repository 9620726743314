<template>
  <div>
    <div class="composers_container">
      <div
        @click="getComposer(composer.id)"
        class="composer"
        v-for="composer in composers"
        :key="composer"
      >
        <div class="composer-names">
          <span>{{ composer.composer }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import {composers} from '../db/composers'



export default {
  data() {
    return {
      composers
    };
  },
  name: "ComposerNavbar",
  components: {

  },
  methods: {
    getComposer(id) {
      this.$emit("getComposer", id);
    },
  },
};
</script>

<style scoped>
.composers_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
  margin: 0 auto;
  margin-top: 2vh;
 background: var(--primary-4-opa);
 padding: 1.5vh 0;
 border-radius: 2vh;
 box-shadow: 1px 2px 6px 0px var(--primary-5-opa);
}

.composer {
  margin: auto
}



.composer-names {
  font-size: 1.6vh;
  font-weight: bold;
  border-bottom: 1px solid transparent;
}

a { text-decoration: none;}

.composer-names:hover {
  cursor: pointer;
  color: var(--primary-5);
}
</style>
