<template>
  <div id="app">
    <Header
      :currentLanguage="currentLanguage"
      @changeLanguage="changeLanguage"
    />
    <div class="container_content">
      <router-view
        :currentLanguage="currentLanguage"
        @changeLanguage="changeLanguage"
      />
    </div>
    <Footer />
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue';
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Footer
  },
  setup() {
    const currentLanguage = ref('english');

    const changeLanguage = (newLanguage) => {
      currentLanguage.value = newLanguage;
    };

    return {
      currentLanguage,
      changeLanguage,
    };
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,400;1,700&display=swap');

:root {
  --primary-1: #191519;
  --primary-2: #FFFFFF;
  --primary-3: #D2D1D3;
  --primary-4: #878280;
  --primary-4-opa: #87828021;
  --primary-5: #4d6374;
  --primary-5-opa: #4d63742e;
  --important: #003beb;
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  font-size: 1.8vh;
  background: var(--primary-3);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-5);
  transition: 0.4s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.container_content {
  position: relative;
  top: 9vh;
  box-sizing: border-box;
  width: 100vw;
  padding: 4vh 5vw 0 5vw;
  height: 87vh;
  margin: auto;
  overflow: auto;
  background-color: var(--primary-3);
  overflow-x: hidden;

}

.button-style {
  border-radius: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3vh;
  width: 200px;
  transition: 0.4s;
}

.button-style:hover {
  cursor: pointer;
}

.topnav_icon {
  height: 30px;
  width: auto;
  transition: 0.4s;
  color: var(--primary-1);
}
.topnav_icon:hover {
  cursor:pointer;
  color: var(--primary-2);
  transform: scale(1.2);
}
</style>
