<template>
  <div class="contact_container">
    <div>
      <p>
        {{ vocabulary['ContactPart1'][currentLanguage] }}
        <a href="mailto:info.arsnovaeditio@gmail.com"
          >info.arsnovaeditio@gmail.com</a
        >
      </p>
      <p>
        {{ vocabulary['ContactPart2'][currentLanguage] }}
      </p>
      <p>
        {{ vocabulary['ContactPart3'][currentLanguage] }}
        <a href="mailto:info.arsnovaeditio@gmail.com"
          >info.arsnovaeditio@gmail.com</a
        >
      </p>
    </div>
    <div class="contact-title">
      {{ vocabulary['MailingAdress'][currentLanguage] }}
    </div>
    <div>Ars Nova Editio kft.</div>
    <div>6000 Kecskemét, Búzavirág utca 67.</div>
    <div>{{ vocabulary['Hungary'][currentLanguage] }}</div>
    <div class="phone-container">
      <PhoneIcon class="phone-icon" />+36 76/ 494 641
    </div>
    <div class="contact-title" style="margin-top: 3vh;">
      {{ vocabulary['BankDetails'][currentLanguage] }}
    </div>
    <div>{{ vocabulary['BankName'][currentLanguage] }} MBH Bank</div>
    <div>
      {{ vocabulary['AccountNumber'][currentLanguage] }} 52000056 - 11017660 -
      00000000
    </div>
    <div>IBAN: HU64 52000056 11017660 00000000</div>
    <div>BIC: MKKBHUHB XXX</div>
  </div>
</template>

<script>
import vocabulary from '../assets/vocabulary.js';
import PhoneIcon from '../assets/icons/PhoneIcon.vue';

export default {
  setup() {
    return {
      vocabulary,
    }
  },
components: { PhoneIcon },
props: {
    currentLanguage: String,
  },
};
</script>

<style scoped>
.contact_container {
  background-color: var(--primary-2);
  border-radius: 2vh;
  padding: 2vh 2vw;
  font-size: 2.2vh;
  margin-top: 1.5vh;
  height: 77vh;
  background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(167, 167, 167, 0.5)),
                  url("../assets//img/backgorund/catalog-bg.png");
}

a {
  color: var(--important);
}

.contact-title {
  font-weight: bold;
  font-size: 1.8vh;
}

.phone-icon {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}

.phone-container {
  display: flex;
  align-items: center;
  margin-top: 2vh;
}
</style>
