<template>
  <div>
    <ComposerNavbar @getComposer="currentComposer" />
    <ComposerBio
      :currentid="id"
      :currentLanguage="currentLanguage"
      @changeLanguage="changeLanguage"
    />
  </div>
</template>

<script>
/* eslint-disable */
import ComposerNavbar from "@/components/ComposerNavbar.vue";
import ComposerBio from "@/components/ComposerBio.vue";

export default {
  data() {
    return { id: 0 };
  },
  name: "Composers",
  components: {
    ComposerNavbar,
    ComposerBio,
  },
  props: {
    currentLanguage: String,
  },
  methods: {
    currentComposer(id) {
      this.id = id;
    },
  },
};
</script>
