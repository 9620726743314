<template>
  <div class="footer_container">
    <span>Copyright Ars Nova Editio, {{ currentYear }}</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
.footer_container {
  color: var(--primary-3);
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 2.5vh;
  font-size: 1.5vh;
  position: fixed;
  bottom: 0;
  background: linear-gradient(125deg, #2e5777, #1f3749);
}
</style>
