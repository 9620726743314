import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Composers from '../views/Composers.vue';
import Publications from '../views/Publications.vue';
import Contact from '../views/Contact.vue';
import Partners from '../views/Partners.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/composers',
    name: 'Composers',
    component: Composers,
  },
  {
    path: '/publications',
    name: 'Publications',
    component: Publications,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/partners',
    name: 'Partners',
    component: Partners,
  },
  {
    path: '/eng/index.html',
    name: 'old',
    component: Home,
  },
  {
    path: '/hun/index.html',
    name: 'old2',
    component: Home,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
