export const products = [
  {
    id: 'AN001',
    composer: 'Orbán György',
    title: 'Daemon irrepit callidus',
    price_printed: 2.9,
    price_digital: 2,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 5,
    duration: '1:30',
    published: '1990',
    img: require('../assets/img/covers/cover_an001.png'),
    video: ' https://www.youtube.com/embed/2NoBKcrDesM?si=bY_vPqhSbMI8gfY5',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an001-1.png'),
    previewImage02: require('../assets/img/preview_gallery/images/an001-2.png'),
  },
  {
    id: 'AN002',
    composer: 'Orbán György',
    title: 'Ave Maria in D',
    price_printed: 2.9,
    price_digital: 2,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 5,
    duration: '2:00',
    published: '1991',
    img: require('../assets/img/covers/cover_an002.png'),
    video: 'https://www.youtube.com/embed/lDmDyDZa3Z4?si=c-TpS1eUF10T5cjH',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an002-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an002-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an002-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an002-4.jpg'),
  },
  {
    id: 'AN003',
    composer: 'Orbán György',
    title: 'Ave verum',
    price_printed: 2,
    price_digital: 1.4,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 3,
    duration: '2:30',
    published: '1994',
    img: require('../assets/img/covers/cover_an003.png'),
    video: 'https://www.youtube.com/embed/XKMDn2x3M_U?si=5IQoyuPTU9p1rmhF',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an003-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an003-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an003-3.jpg'),
  },

  {
    id: 'AN004',
    composer: 'Orbán György',
    title: 'Ad nocturnum',
    price_printed: 2.9,
    price_digital: 2,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 5,
    duration: '2:30',
    published: '2001',
    img: require('../assets/img/covers/cover_an004.png'),
    video: 'https://www.youtube.com/embed/l2MHwcbjK4A?si=liVOyuteKzpV2Cza',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an004-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an004-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an004-3.jpg'),
  },
  {
    id: 'AN005',
    composer: 'Orbán György',
    title: 'Ave Maris Stella',
    price_printed: 2.9,
    price_digital: 2,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 5,
    duration: '3:00',
    published: '2001',
    img: require('../assets/img/covers/cover_an005.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an005-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an005-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an005-3.jpg'),
  },
  {
    id: 'AN006',
    composer: 'Orbán György',
    title: 'Ave Maria in A',
    price_printed: 2,
    price_digital: 1.4,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 3,
    duration: '2:30',
    published: '1994',
    img: require('../assets/img/covers/cover_an006.png'),
    video: 'https://www.youtube.com/embed/MdbRB0CHqYg?si=CjRE_fjffcHNuQ2u',
    isAvailablePrinted: true,
    isAvailableDigital: false,
  },
  {
    id: 'AN007',
    composer: 'Orbán György',
    title: 'Veni sancte spiritus',
    price_printed: 2,
    price_digital: 1.4,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 3,
    duration: '2:00',
    published: '2001',
    img: require('../assets/img/covers/cover_an007.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an007-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an007-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an007-3.jpg'),
  },
  {
    id: 'AN008',
    composer: 'Selmeczi György',
    title: 'Az elveszített végzet',
    price_printed: 1.75,
    price_digital: 1.2,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 2,
    duration: '2:00',
    published: '2004',
    img: require('../assets/img/covers/cover_an008.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: false,
    previewImage01: require('../assets/img/preview_gallery/images/an008-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an008-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an008-3.jpg'),
  },
  {
    id: 'AN009',
    composer: 'Selmeczi György',
    title: 'Az ismeretlen kedveshez',
    price_printed: 2,
    price_digital: 1.4,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 3,
    duration: '2:00',
    published: '2004',
    img: require('../assets/img/covers/cover_an009.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: false,
    previewImage01: require('../assets/img/preview_gallery/images/an009-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an009-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an009-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an009-4.jpg'),
    previewImage05: require('../assets/img/preview_gallery/images/an009-5.jpg'),
  },
  {
    id: 'AN010',
    composer: 'Selmeczi György',
    title: 'A garabonciások énekeiből',
    price_printed: 2.9,
    price_digital: 2,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 5,
    duration: '2:00',
    published: '2004',
    img: require('../assets/img/covers/cover_an010.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: false,
    previewImage01: require('../assets/img/preview_gallery/images/an010-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an010-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an010-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an010-4.jpg'),
  },
  {
    id: 'AN011',
    composer: 'Vajda János',
    title: 'Sequentia de Virgine Maria',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 4,
    duration: '3:00',
    published: '1992',
    img: require('../assets/img/covers/cover_an011.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: false,
    previewImage01: require('../assets/img/preview_gallery/images/an011-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an011-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an011-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an011-4.jpg'),
    previewImage05: require('../assets/img/preview_gallery/images/an011-5.jpg'),
  },
  {
    id: 'AN012',
    composer: 'Vajda János',
    title: 'Ave Maria',
    price_printed: 2,
    price_digital: 1.4,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 3,
    duration: '3:00',
    published: '1992',
    img: require('../assets/img/covers/cover_an012.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: false,
    previewImage01: require('../assets/img/preview_gallery/images/an012-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an012-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an012-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an012-4.jpg'),
    previewImage05: require('../assets/img/preview_gallery/images/an012-5.jpg'),
  },
  {
    id: 'AN013',
    composer: 'Csemiczky Miklós',
    title: 'Ave Maria in D',
    price_printed: 2.9,
    price_digital: 2,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 5,
    duration: '3:00',
    published: '2000',
    img: require('../assets/img/covers/cover_an013.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: false,
    previewImage01: require('../assets/img/preview_gallery/images/an013-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an013-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an013-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an013-4.jpg'),
    previewImage05: require('../assets/img/preview_gallery/images/an013-5.jpg'),
  },
  {
    id: 'AN014',
    composer: 'Csemiczky Miklós',
    title: 'Ave Maris Stella',
    price_printed: 4.35,
    price_digital: 3.0,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 10,
    duration: '3:00',
    published: '2000',
    img: require('../assets/img/covers/cover_an014.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: false,
  },
  {
    id: 'AN015',
    composer: 'Csemiczky Miklós',
    title: 'Ave verum corpus',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 4,
    duration: '4:00',
    published: '1994',
    img: require('../assets/img/covers/cover_an015.png'),
    video: 'https://www.youtube.com/embed/a7wl2eDNjww?si=nMEhXDshnUK0x4hr',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an015-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an015-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an015-3.jpg'),
  },
  {
    id: 'AN016',
    composer: 'Csemiczky Miklós',
    title: 'Pater noster',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 4,
    duration: '4:00',
    published: '1991',
    img: require('../assets/img/covers/cover_an016.png'),
    video: 'https://www.youtube.com/embed/U6BkzimZHLE?si=OOII6xC34K5VEEMb',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an016-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an016-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an016-3.jpg'),
  },
  {
    id: 'AN017',
    composer: 'Csemiczky Miklós',
    title: 'Deus, creator omnium',
    price_printed: 3.5,
    price_digital: 2.4,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 7,
    duration: '5:00',
    published: '1994',
    img: require('../assets/img/covers/cover_an017.png'),
    video: 'https://www.youtube.com/embed/tWa7s3GBHI0?si=gojhitk3wGduDzO9',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an017-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an017-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an017-3.jpg'),
  },
  {
    id: 'AN018',
    composer: 'Csemiczky Miklós',
    title: 'Da pacem Domine',
    price_printed: 2,
    price_digital: 1.4,
    orch: 'SATBB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 3,
    duration: '3:00',
    published: '1994',
    img: require('../assets/img/covers/cover_an018.png'),
    video: 'https://www.youtube.com/embed/XKfZzsFxqu0?si=WrI9g2yvBg34SbRU',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an018-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an018-2.jpg'),
  },
  {
    id: 'AN019',
    composer: 'Orbán György',
    title: 'Fülemüle',
    price_printed: 2.9,
    price_digital: 2,
    orch: 'SSA',
    type: 'Female',
    skill: 'Medium',
    pages: 5,
    duration: '2:00',
    published: '2004',
    img: require('../assets/img/covers/cover_an019.png'),
    video: 'https://www.youtube.com/embed/i2v3sPSC51o?si=KFTG6_1NPm_EKkG2',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an019-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an019-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an019-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an019-4.jpg'),
  },
  {
    id: 'AN020',
    composer: 'Orbán György',
    title: 'Pater noster in B',
    price_printed: 2,
    price_digital: 1.4,
    orch: 'SSA',
    type: 'Female',
    skill: 'Medium',
    pages: 3,
    duration: '2:30',
    published: '2007',
    img: require('../assets/img/covers/cover_an020.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an020-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an020-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an020-3.jpg'),
  },
  {
    id: 'AN021',
    composer: 'Orbán György',
    title: 'Horae',
    price_printed: 3.2,
    price_digital: 2.2,
    orch: 'SSAA',
    type: 'Female',
    skill: 'Advanced',
    pages: 6,
    duration: '5:00',
    published: '2010',
    img: require('../assets/img/covers/cover_an021.png'),
    video: 'https://www.youtube.com/embed/9unFc9xuZ7k?si=R1pR03uSNxab4O9P',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an021-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an021-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an021-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an021-4.jpg'),
  },
  {
    id: 'AN022',
    composer: 'Orbán György',
    title: 'Juxta crucem',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'SSAA',
    type: 'Female',
    skill: 'Medium',
    pages: 4,
    duration: '2:00',
    published: '2010',
    img: require('../assets/img/covers/cover_an022.png'),
    video: 'https://www.youtube.com/embed/4IkW2X_PiWc?si=7pDac_ozCpKWhoGq',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an022-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an022-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an022-3.jpg'),
  },
  {
    id: 'AN023',
    composer: 'Csemiczky Miklós',
    title: 'Sequentia de Virgine Maria',
    price_printed: 1.75,
    price_digital: 1.2,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 2,
    duration: '2:00',
    published: '1990',
    img: require('../assets/img/covers/cover_an023.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an023-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an023-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an023-3.jpg'),
  },
  {
    id: 'AN024',
    composer: 'Orbán György',
    title: 'Lanthúr ha szól',
    price_printed: 2.9,
    price_digital: 2,
    orch: 'SMsATBB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 5,
    duration: '3:30',
    published: '2007',
    img: require('../assets/img/covers/cover_an024.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an024-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an024-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an024-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an024-4.jpg'),
  },
  {
    id: 'AN025',
    composer: 'Csemiczky Miklós',
    title: 'Ave Maria in E',
    price_printed: 2,
    price_digital: 1.4,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 3,
    duration: '2:00',
    published: '1994',
    img: require('../assets/img/covers/cover_an025.png'),
    video: 'https://www.youtube.com/embed/zliSb1RTvGI?si=4HKesphX0oB0Y_i7',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an025-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an025-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an025-3.jpg'),
  },
  {
    id: 'AN026',
    composer: 'Gyöngyösi Levente',
    title: 'Cantate Domino',
    price_printed: 3.2,
    price_digital: 2.2,
    orch: 'SSAA',
    type: 'Female',
    skill: 'Medium',
    pages: 6,
    duration: '3:00',
    published: '1997',
    img: require('../assets/img/covers/cover_an026.png'),
    video: 'https://www.youtube.com/embed/T8fpUSqWsZY?si=ctONouDWkl_k37Lt',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an026-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an026-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an026-3.jpg'),
  },
  {
    id: 'AN027',
    composer: 'Orbán György',
    title: 'Pange lingua',
    price_printed: 4.95,
    price_digital: 3.4,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 12,
    duration: '2:30',
    published: '1997',
    img: require('../assets/img/covers/cover_an027.png'),
    video: 'https://www.youtube.com/embed/fDmmAS49k0Q?si=YRKJSCLL9pn7Bszi',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an027-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an027-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an027-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an027-4.jpg'),
    previewImage05: require('../assets/img/preview_gallery/images/an027-5.jpg'),
  },
  {
    id: 'AN028',
    composer: 'Orbán György',
    title: 'Gágogó',
    price_printed: 3.2,
    price_digital: 2.2,
    orch: 'SSA',
    type: 'Female',
    skill: 'Medium',
    pages: 6,
    duration: '2:00',
    published: '2001',
    img: require('../assets/img/covers/cover_an028.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an028-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an028-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an028-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an028-4.jpg'),
    previewImage05: require('../assets/img/preview_gallery/images/an028-5.jpg'),
  },
  {
    id: 'AN029',
    composer: 'Orbán György',
    title: 'Ludvércz',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'SSA',
    type: 'Female',
    skill: 'Advanced',
    pages: 4,
    duration: '2:00',
    published: '2001',
    img: require('../assets/img/covers/cover_an029.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an029-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an029-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an029-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an029-4.jpg'),
  },
  {
    id: 'AN030',
    composer: 'Orbán György',
    title: 'Hajnalban',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'SSA',
    type: 'Female',
    skill: 'Advanced',
    pages: 4,
    duration: '2:00',
    published: '2001',
    img: require('../assets/img/covers/cover_an030.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an030-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an030-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an030-3.jpg'),
  },
  {
    id: 'AN031',
    composer: 'Gyöngyösi Levente',
    title: 'Ha én kedvesemről gondolkodom',
    price_printed: 4.65,
    price_digital: 3.2,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 11,
    duration: '3:00',
    published: '2003',
    img: require('../assets/img/covers/cover_an031.png'),
    video: 'https://www.youtube.com/embed/vTpIHt09HNA?si=ern3pm2r6e_q5XWb',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an031-1.png'),
    previewImage02: require('../assets/img/preview_gallery/images/an031-2.png'),
    previewImage03: require('../assets/img/preview_gallery/images/an031-3.png'),
  },
  {
    id: 'AN032',
    composer: 'Vajda János',
    title: 'O, lux',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'SSA',
    type: 'Female',
    skill: 'Medium',
    pages: 4,
    duration: '2:00',
    published: '1995',
    img: require('../assets/img/covers/cover_an032.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an032-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an032-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an032-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an032-4.jpg'),
    previewImage05: require('../assets/img/preview_gallery/images/an032-5.jpg'),
  },
  {
    id: 'AN033',
    composer: 'Orbán György',
    title: 'Ave Maria, graziosa',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'SA',
    type: 'Female',
    skill: 'Medium',
    pages: 4,
    duration: '1:30',
    published: '2006',
    img: require('../assets/img/covers/cover_an033.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an033-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an033-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an033-3.jpg'),
  },
  {
    id: 'AN034',
    composer: 'Szakács A. Csaba',
    title: 'Szüvem el ne alugyék',
    price_printed: 2.9,
    price_digital: 2,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 5,
    duration: '3:00',
    published: '2003',
    img: require('../assets/img/covers/cover_an034.png'),
    video: 'https://www.youtube.com/embed/XDrATPPgcbk?si=msAbD3eITwGq3vKk',
    isAvailablePrinted: true,
    isAvailableDigital: false,
  },
  {
    id: 'AN035',
    composer: 'Orbán György',
    title: 'O Gloriosa',
    price_printed: 2,
    price_digital: 1.4,
    orch: 'SSA',
    type: 'Female',
    skill: 'Beginner',
    pages: 3,
    duration: '1:30',
    published: '2010',
    img: require('../assets/img/covers/cover_an035.png'),
    video: 'https://www.youtube.com/embed/fDjrJCd3An0?si=F9wBmgGaV5YxbTUB',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an035-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an035-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an035-3.jpg'),
  },
  {
    id: 'AN037',
    composer: 'Orbán György',
    title: 'Addio - Három gyermekkar',
    price_printed: 3.5,
    price_digital: 2.4,
    orch: 'SSA',
    type: 'Female',
    skill: 'Medium',
    pages: 7,
    duration: '5:00',
    published: '1995',
    img: require('../assets/img/covers/cover_an037.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: false,
    previewImage01: require('../assets/img/preview_gallery/images/an037-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an037-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an037-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an037-4.jpg'),
    previewImage05: require('../assets/img/preview_gallery/images/an037-5.jpg'),
    previewImage06: require('../assets/img/preview_gallery/images/an037-6.jpg'),
    previewImage07: require('../assets/img/preview_gallery/images/an037-7.jpg'),
    previewImage08: require('../assets/img/preview_gallery/images/an037-8.jpg'),
  },
  {
    id: 'AN038',
    composer: 'Orbán György',
    title: 'Missa Quinta',
    price_printed: 16.25,
    price_digital: 12.5,
    orch: 'SSATBB, cl., db.',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 58,
    duration: '15:00',
    published: '2005',
    img: require('../assets/img/covers/cover_an038.png'),
    video: 'https://www.youtube.com/embed/or33dJChjVU?si=qy6eRICPiV7uDeyK',
    isAvailablePrinted: true,
    isAvailableDigital: true,
  },
  {
    id: 'AN039',
    composer: 'Orbán György',
    title: 'Adeste fideles',
    price_printed: 2.9,
    price_digital: 2,
    orch: 'SSAA',
    type: 'Female',
    skill: 'Advanced',
    pages: 5,
    duration: '3:00',
    published: '1994',
    img: require('../assets/img/covers/cover_an039.png'),
    video: 'https://www.youtube.com/embed/CWvJq4LRF50?si=9DEUnXYHtcoS4EPd',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an039-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an039-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an039-3.jpg'),
  },
  {
    id: 'AN040',
    composer: 'Orbán György',
    title: 'Audi voces',
    price_printed: 1.75,
    price_digital: 1.2,
    orch: 'SSA',
    type: 'Female',
    skill: 'Medium',
    pages: 2,
    duration: '1:30',
    published: '2001',
    img: require('../assets/img/covers/cover_an040.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an040-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an040-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an040-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an040-4.jpg'),
  },
  {
    id: 'AN041',
    composer: 'Orbán György',
    title: 'Noli flere',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'SSA',
    type: 'Female',
    skill: 'Medium',
    pages: 4,
    duration: '1:30',
    published: '2004',
    img: require('../assets/img/covers/cover_an041.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an041-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an041-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an041-3.jpg'),
  },
  {
    id: 'AN042',
    composer: 'Farkas Ferenc',
    title: 'Missa Hungarica',
    price_printed: 6.95,
    price_digital: 4.8,
    orch: 'SATB, organ',
    type: 'Mixed',
    skill: 'Medium',
    pages: 19,
    duration: '12:00',
    published: '1992',
    img: require('../assets/img/covers/cover_an042.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an042-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an042-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an042-3.jpg'),
  },
  {
    id: 'AN043',
    composer: 'Orbán György',
    title: 'Come Away',
    price_printed: 2.9,
    price_digital: 2,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 5,
    duration: '2:30',
    published: '2005',
    img: require('../assets/img/covers/cover_an043.png'),
    video: 'https://www.youtube.com/embed/tG5WCNdpon8?si=jNlJerRAORiYR80r',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an043-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an043-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an043-3.jpg'),
  },
  {
    id: 'AN044',
    composer: 'Orbán György',
    title: 'Regis regum civis',
    price_printed: 3.2,
    price_digital: 2.2,
    orch: 'SMsATBB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 6,
    duration: '3:30',
    published: '2000',
    img: require('../assets/img/covers/cover_an044.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an044-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an044-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an044-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an044-4.jpg'),
  },
  {
    id: 'AN045',
    composer: 'Orbán György',
    title: 'Nobis Natus',
    price_printed: 3.5,
    price_digital: 2.4,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 7,
    duration: '2:00',
    published: '2003',
    img: require('../assets/img/covers/cover_an045.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an045-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an045-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an045-3.jpg'),
  },
  {
    id: 'AN046',
    composer: 'Orbán György',
    title: 'Sicut cervus desiderat',
    price_printed: 6.4,
    price_digital: 4.4,
    orch: 'SSAA',
    type: 'Female',
    skill: 'Advanced',
    pages: 17,
    duration: '6:30',
    published: '2003',
    img: require('../assets/img/covers/cover_an046.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an046-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an046-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an046-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an046-4.jpg'),
  },
  {
    id: 'AN048',
    composer: 'Orbán György',
    title: 'Pange lingua',
    price_printed: 3.75,
    price_digital: 2.6,
    orch: 'SSA',
    type: 'Female',
    skill: 'Advanced',
    pages: 8,
    duration: '3:00',
    published: '1988',
    img: require('../assets/img/covers/cover_an048.png'),
    video: 'https://www.youtube.com/embed/ggf7-WNC5MY?si=dmpO-jDl7MyKRWmI',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an048-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an048-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an048-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an048-4.jpg'),
  },
  {
    id: 'AN049',
    composer: 'Csemiczky Miklós',
    title: 'O, Beata virgo Maria',
    price_printed: 3.5,
    price_digital: 2.4,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 7,
    duration: '5:45',
    published: '1995',
    img: require('../assets/img/covers/cover_an049.png'),
    video: 'https://www.youtube.com/embed/QtsPAZHC7IA?si=HHhEp1gAxoj21tjQ',
    isAvailablePrinted: true,
    isAvailableDigital: true,
  },
  {
    id: 'AN050',
    composer: 'Orbán György',
    title: 'Timor et tremor',
    price_printed: 2.9,
    price_digital: 2,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 5,
    duration: '3:00',
    published: '2010',
    img: require('../assets/img/covers/cover_an050.png'),
    video: 'https://www.youtube.com/embed/vQc-4_D7V4M?si=BYiKCjwS3d2uKPlj',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an050-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an050-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an050-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an050-4.jpg'),
    previewImage05: require('../assets/img/preview_gallery/images/an050-5.jpg'),
    previewImage06: require('../assets/img/preview_gallery/images/an050-6.jpg'),
  },
  {
    id: 'AN051',
    composer: 'Orbán György',
    title: 'Nunc dimittis',
    price_printed: 2,
    price_digital: 1.4,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 3,
    duration: '2:30',
    published: '2004',
    img: require('../assets/img/covers/cover_an051.png'),
    video: 'https://www.youtube.com/embed/ha3YJ6osVes?si=hNzRSOnSL21CAxnB',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an051-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an051-2.jpg'),
  },
  {
    id: 'AN052',
    composer: 'Vajda János',
    title: 'Scherzo',
    price_printed: 3.75,
    price_digital: 2.6,
    orch: 'SSA',
    type: 'Female',
    skill: 'Medium',
    pages: 8,
    duration: '3:00',
    published: '1995',
    img: require('../assets/img/covers/cover_an052.png'),
    video: 'https://www.youtube.com/embed/_npegD7ch-8?si=8bbt052XY_oYDmoa',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an052-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an052-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an052-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an052-4.jpg'),
    previewImage05: require('../assets/img/preview_gallery/images/an052-5.jpg'),
  },
  {
    id: 'AN053',
    composer: 'Orbán György',
    title: 'Psalmus 126',
    price_printed: 3.2,
    price_digital: 2.2,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 6,
    duration: '3:30',
    published: '2010',
    img: require('../assets/img/covers/cover_an053.png'),
    video: 'https://www.youtube.com/embed/vWNHO460F5Q?si=DYl74UmT09cF-_rp',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an053-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an053-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an053-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an053-4.jpg'),
  },
  {
    id: 'AN054',
    composer: 'Orbán György',
    title: 'O, Mistress mine',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 4,
    duration: '1:00',
    published: '2010',
    img: require('../assets/img/covers/cover_an054.png'),
    video: 'https://www.youtube.com/embed/4D3fPQXbGr0?si=0h514_7ftYNwBo3e',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an054-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an054-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an054-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an054-4.jpg'),
    previewImage05: require('../assets/img/preview_gallery/images/an054-5.jpg'),
  },
  {
    id: 'AN055',
    composer: 'Orbán György',
    title: 'Te lucis ante terminus',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 4,
    duration: '2:30',
    published: '2013',
    img: require('../assets/img/covers/cover_an055.png'),
    video: 'https://www.youtube.com/embed/SQsrYwCIyTc?si=Oo4nfbJzwDG0_UIg',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an055-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an055-2.jpg'),
  },
  {
    id: 'AN056',
    composer: 'Orbán György',
    title: 'Amor sanctus',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 4,
    duration: '3:00',
    published: '2001',
    img: require('../assets/img/covers/cover_an056.png'),
    video: 'https://www.youtube.com/embed/oXws3Ku0INc?si=2adcpnbhXuon2mrA',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an056-1.png'),
  },
  {
    id: 'AN057',
    composer: 'Orbán György',
    title: 'Ave Regina',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 4,
    duration: '2:00',
    published: '2001',
    img: require('../assets/img/covers/cover_an057.png'),
    video: 'https://www.youtube.com/embed/vrYBvY3plSw?si=px3pE_zSmFNHF7Kp',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an057-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an057-2.jpg'),
  },
  {
    id: 'AN058',
    composer: 'Orbán György',
    title: 'Urbs caelestis',
    price_printed: 2,
    price_digital: 1.4,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 3,
    duration: '2:00',
    published: '2001',
    img: require('../assets/img/covers/cover_an058.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an058-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an058-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an058-3.jpg'),
  },
  {
    id: 'AN059',
    composer: 'Orbán György',
    title: 'O Maria',
    price_printed: 1.75,
    price_digital: 1.2,
    orch: 'SMsA',
    type: 'Children',
    skill: 'Beginner',
    pages: 2,
    duration: '2:00',
    published: '2012',
    img: require('../assets/img/covers/cover_an059.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an059-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an059-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an059-3.jpg'),
  },
  {
    id: 'AN060',
    composer: 'Orbán György',
    title: 'Missa Duodecima',
    price_printed: 20.8,
    price_digital: 16.0,
    orch: 'SSAA, instrument',
    type: 'Female',
    skill: 'Advanced',
    pages: 63,
    duration: '18:00',
    published: '2007',
    img: require('../assets/img/covers/cover_an060.png'),
    video: 'https://www.youtube.com/embed/Joc_LAn6F48?si=bRfUhPyY5UoxOKL5',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an060-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an060-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an060-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an060-4.jpg'),
    previewImage05: require('../assets/img/preview_gallery/images/an060-5.jpg'),
    previewImage06: require('../assets/img/preview_gallery/images/an060-6.jpg'),
    previewImage07: require('../assets/img/preview_gallery/images/an060-7.jpg'),
    previewImage08: require('../assets/img/preview_gallery/images/an060-8.jpg'),
  },
  {
    id: 'AN061',
    composer: 'Orbán György',
    title: 'Kalandorok kíméljenek',
    price_printed: 1.15,
    price_digital: 0.8,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 1,
    duration: '00:30',
    published: '2013',
    img: require('../assets/img/covers/cover_an061.png'),
    video: 'https://www.youtube.com/embed/HxWenQesNHo?si=XUTDw3M-sWR3a4aZ',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an061-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an061-2.jpg'),
  },
  {
    id: 'AN062',
    composer: 'Orbán György',
    title: 'Elefánt voltam',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 4,
    duration: '01:30',
    published: '2013',
    img: require('../assets/img/covers/cover_an062.png'),
    video: 'https://www.youtube.com/embed/UDA6lNu4Ne0?si=qrMm0OqW25n8kL-G',
    isAvailablePrinted: true,
    isAvailableDigital: true,
  },
  {
    id: 'AN063',
    composer: 'Orbán György',
    title: 'Paprikajancsi szerenádja',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 4,
    duration: '01:30',
    published: '2013',
    img: require('../assets/img/covers/cover_an063.png'),
    video: 'https://www.youtube.com/embed/SjyPFdpT8Hk?si=o8F6js2X_xca8yAN',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an063-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an063-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an063-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an063-4.jpg'),
    previewImage05: require('../assets/img/preview_gallery/images/an063-5.jpg'),
  },
  {
    id: 'AN064',
    composer: 'Orbán György',
    title: 'Porszem mászik',
    price_printed: 3.2,
    price_digital: 2.2,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 6,
    duration: '02:30',
    published: '2013',
    img: require('../assets/img/covers/cover_an064.png'),
    video: 'https://www.youtube.com/embed/8cd5Ws9BCaw?si=uh96TfCo1y7jqP8Z',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an064-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an064-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an064-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an064-4.jpg'),
  },
  {
    id: 'AN065',
    composer: 'Orbán György',
    title: 'Panyigai friss',
    price_printed: 1.15,
    price_digital: 0.8,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 1,
    duration: '00:30',
    published: '2013',
    img: require('../assets/img/covers/cover_an065.png'),
    video: 'https://www.youtube.com/embed/dpHnGxXUG9E?si=_I0B7gDJ3KfMUPWn',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an065-1.jpg'),
  },
  {
    id: 'AN066',
    composer: 'Orbán György',
    title: 'Tisztátlan forrásból',
    price_printed: 1.75,
    price_digital: 0.8,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 2,
    duration: '02:00',
    published: '2013',
    img: require('../assets/img/covers/cover_an066.png'),
    video: 'https://www.youtube.com/embed/t7j1GOFoamw?si=jSavxq8twkqi0GQv',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an066-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an066-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an066-3.jpg'),
  },
  {
    id: 'AN067',
    composer: 'Orbán György',
    title: 'Titkok nyitja',
    price_printed: 2.9,
    price_digital: 2,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 5,
    duration: '03:30',
    published: '2013',
    img: require('../assets/img/covers/cover_an067.png'),
    video: 'https://www.youtube.com/embed/qeROXlAtvCM?si=s44I57lXS12E1P_n',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an067-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an067-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an067-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an067-4.jpg'),
  },
  {
    id: 'AN068',
    composer: 'Orbán György',
    title: 'A cselédlány könnye',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 4,
    duration: '01:30',
    published: '1997',
    img: require('../assets/img/covers/cover_an068.png'),
    video: 'https://www.youtube.com/embed/wheEmGUGeCQ?si=B2Lrg1KIxaq6Fo3w',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an068-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an068-2.jpg'),
  },
  {
    id: 'AN069',
    composer: 'Orbán György',
    title: 'Nagyapám int',
    price_printed: 2,
    price_digital: 1.4,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 3,
    duration: '02:00',
    published: '2013',
    img: require('../assets/img/covers/cover_an069.png'),
    video: 'https://www.youtube.com/embed/MGPx7bdHUFg?si=YpPqvNvPpc-hyAcJ',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an069-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an069-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an069-3.jpg'),
  },
  {
    id: 'AN070',
    composer: 'Orbán György',
    title: 'Hintafa',
    price_printed: 4.35,
    price_digital: 3,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 10,
    duration: '03:00',
    published: '2013',
    img: require('../assets/img/covers/cover_an070.png'),
    video: 'https://www.youtube.com/embed/8UF7Ub9dBvg?si=D9LOfXpfeHiXMlUn',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an070-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an070-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an070-3.jpg'),
  },
  {
    id: 'AN071',
    composer: 'Orbán György',
    title: 'Ave Regina á 3',
    price_printed: 1.75,
    price_digital: 1.2,
    orch: 'SMsA',
    type: 'Female',
    skill: 'Medium',
    pages: 2,
    duration: '02:00',
    published: '2010',
    img: require('../assets/img/covers/cover_an071.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an071-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an071-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an071-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an071-4.jpg'),
  },
  {
    id: 'AN072',
    composer: 'Orbán György',
    title: 'Regina caeli á 3',
    price_printed: 1.75,
    price_digital: 1.2,
    orch: 'SMsA',
    type: 'Female',
    skill: 'Medium',
    pages: 2,
    duration: '02:00',
    published: '2010',
    img: require('../assets/img/covers/cover_an072.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an072-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an072-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an072-3.jpg'),
  },
  {
    id: 'AN073',
    composer: 'Orbán György',
    title: 'Salve Regina á 3',
    price_printed: 2,
    price_digital: 1.4,
    orch: 'SMsA',
    type: 'Female',
    skill: 'Medium',
    pages: 3,
    duration: '03:00',
    published: '2010',
    img: require('../assets/img/covers/cover_an073.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an073-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an073-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an073-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an073-4.jpg'),
  },
  {
    id: 'AN074',
    composer: 'Orbán György',
    title: 'Alma redemptoris mater á 3',
    price_printed: 2,
    price_digital: 1.4,
    orch: 'SMsA',
    type: 'Female',
    skill: 'Medium',
    pages: 3,
    duration: '02:30',
    published: '2010',
    img: require('../assets/img/covers/cover_an074.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an074-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an074-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an074-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an074-4.jpg'),
  },
  {
    id: 'AN075',
    composer: 'Orbán György',
    title: 'Ave Regina (for 3 voices)',
    price_printed: 1.75,
    price_digital: 1.2,
    orch: 'SAB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 2,
    duration: '02:00',
    published: '2010',
    img: require('../assets/img/covers/cover_an075.png'),
    video: 'https://www.youtube.com/embed/-SUFT96LRMg?si=gVl2KlNeXb-XSuB6',
    isAvailablePrinted: true,
    isAvailableDigital: true,
  },
  {
    id: 'AN076',
    composer: 'Orbán György',
    title: 'Regina caeli (for 3 voices)',
    price_printed: 1.75,
    price_digital: 1.2,
    orch: 'SAB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 2,
    duration: '02:00',
    published: '2010',
    img: require('../assets/img/covers/cover_an076.png'),
    video: 'https://www.youtube.com/embed/7gsYDsurV-o?si=8JhxPxFLDPpz6hVv',
    isAvailablePrinted: true,
    isAvailableDigital: true,
  },
  {
    id: 'AN077',
    composer: 'Orbán György',
    title: 'Salve Regina (for 3 voices)',
    price_printed: 2,
    price_digital: 1.4,
    orch: 'SAB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 3,
    duration: '03:00',
    published: '2010',
    img: require('../assets/img/covers/cover_an077.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an077-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an077-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an077-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an077-4.jpg'),
  },
  {
    id: 'AN078',
    composer: 'Orbán György',
    title: 'Alma redemptoris mater',
    price_printed: 2,
    price_digital: 1.4,
    orch: 'SAB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 3,
    duration: '02:30',
    published: '2010',
    img: require('../assets/img/covers/cover_an078.png'),
    video: 'https://www.youtube.com/embed/ch8CSTF__2Y?si=W0zMD45ZbnIW0cHr',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an078-1.png'),
  },
  {
    id: 'AN079',
    composer: 'Csemiczky Miklós',
    title: 'Ave Maria',
    price_printed: 2,
    price_digital: 1.4,
    orch: 'SSA',
    type: 'Female',
    skill: 'Medium',
    pages: 2,
    duration: '02:00',
    published: '2001',
    img: require('../assets/img/covers/cover_an079.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an079-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an079-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an079-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an079-4.jpg'),
  },
  {
    id: 'AN080',
    composer: 'Csemiczky Miklós',
    title: 'Missa brevis',
    price_printed: 6.65,
    price_digital: 4.6,
    orch: 'SSAA',
    type: 'Children',
    skill: 'Medium',
    pages: 18,
    duration: '08:00',
    published: '2003',
    img: require('../assets/img/covers/cover_an080.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an080-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an080-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an080-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an080-4.jpg'),
    previewImage05: require('../assets/img/preview_gallery/images/an080-5.jpg'),
  },
  {
    id: 'AN081',
    composer: 'Csemiczky Miklós',
    title: 'Tristis est anima mea',
    price_printed: 4.5,
    price_digital: 2.8,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 9,
    duration: '04:00',
    published: '2005',
    img: require('../assets/img/covers/cover_an081.png'),
    video: 'https://www.youtube.com/embed/Zz-d9LP3BY8?si=8-QtYagWZuBzeVLy',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an081-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an081-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an081-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an081-4.jpg'),
  },
  {
    id: 'AN082',
    composer: 'Selmeczi György',
    title: 'Szent János himnusz (unison)',
    price_printed: 1.15,
    price_digital: 0.8,
    orch: 'SATB, organ',
    type: 'Mixed',
    skill: 'Medium',
    pages: 1,
    duration: '01:00',
    published: '1998',
    img: require('../assets/img/covers/cover_an082.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an082-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an082-2.jpg'),
  },
  {
    id: 'AN083',
    composer: 'Selmeczi György',
    title: 'Szent János himnusz (unison)',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'SATB, instrument',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 4,
    duration: '02:30',
    published: '1998',
    img: require('../assets/img/covers/cover_an083.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an083-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an083-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an083-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an083-4.jpg'),
    previewImage05: require('../assets/img/preview_gallery/images/an083-5.jpg'),
  },
  {
    id: 'AN084',
    composer: 'Selmeczi György',
    title: 'Hymnus in festo sancti Emericis',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'SATB, instrument',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 4,
    duration: '02:30',
    published: '1998',
    img: require('../assets/img/covers/cover_an084.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: false,
    previewImage01: require('../assets/img/preview_gallery/images/an084-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an084-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an084-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an084-4.jpg'),
  },
  {
    id: 'AN085',
    composer: 'Selmeczi György',
    title: 'Szent Borbála',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'TB, instrument',
    type: 'Mixed',
    skill: 'Medium',
    pages: 4,
    duration: '02:00',
    published: '2000',
    img: require('../assets/img/covers/cover_an085.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an085-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an085-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an085-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an085-4.jpg'),
  },
  {
    id: 'AN086',
    composer: 'Selmeczi György',
    title: 'Szent Margit (unison)',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'SATB, instrument',
    type: 'Mixed',
    skill: 'Medium',
    pages: 4,
    duration: '02:00',
    published: '1998',
    img: require('../assets/img/covers/cover_an086.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an086-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an086-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an086-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an086-4.jpg'),
  },
  {
    id: 'AN087',
    composer: 'Selmeczi György',
    title: 'Szent László himnusz',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'SATB, instrument',
    type: 'Mixed',
    skill: 'Medium',
    pages: 4,
    duration: '03:00',
    published: '2000',
    img: require('../assets/img/covers/cover_an087.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an087-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an087-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an087-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an087-4.jpg'),
    previewImage05: require('../assets/img/preview_gallery/images/an087-5.jpg'),
  },
  {
    id: 'AN088',
    composer: 'Selmeczi György',
    title: 'Agnus Dei a Tisza oratóriumból',
    price_printed: 3.5,
    price_digital: 2.4,
    orch: 'SS, SATB, piano',
    type: 'Mixed',
    skill: 'Beginner',
    pages: 7,
    duration: '05:00',
    published: '2000',
    img: require('../assets/img/covers/cover_an088.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an088-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an088-2.jpg'),
  },
  {
    id: 'AN095',
    composer: 'Vajda János',
    title: 'Kilenc bagatell',
    price_printed: 13,
    price_digital: 9,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Beginner',
    pages: 40,
    duration: '12:00',
    published: '1995',
    img: require('../assets/img/covers/cover_an095.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an095-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an095-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an095-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an095-4.jpg'),
    previewImage05: require('../assets/img/preview_gallery/images/an095-5.jpg'),
    previewImage06: require('../assets/img/preview_gallery/images/an095-6.jpg'),
    previewImage07: require('../assets/img/preview_gallery/images/an095-7.jpg'),
    previewImage08: require('../assets/img/preview_gallery/images/an095-8.jpg'),
    previewImage09: require('../assets/img/preview_gallery/images/an095-9.jpg'),
  },
  {
    id: 'AN096',
    composer: 'Selmeczi György',
    title: 'Himnuszok (AN082 - AN087)',
    price_printed: 10.44,
    price_digital: 7.2,
    orch: 'SATB, instrument',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 31,
    duration: '13:00',
    published: '2002',
    img: require('../assets/img/covers/cover_an096.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an098-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an098-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an098-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an098-4.jpg'),
    previewImage05: require('../assets/img/preview_gallery/images/an098-5.jpg'),
  },
  {
    id: 'AN097',
    composer: 'Orbán György',
    title: 'Vále, vále',
    price_printed: 3.5,
    price_digital: 2.4,
    orch: 'SSAA',
    type: 'Female',
    skill: 'Advanced',
    pages: 7,
    duration: '03:30',
    published: '2009',
    img: require('../assets/img/covers/cover_an097.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
  },
  {
    id: 'AN098',
    composer: 'Orbán György',
    title: 'A fejedelem kertje',
    price_printed: 3.5,
    price_digital: 2.4,
    orch: 'SSAA',
    type: 'Female',
    skill: 'Advanced',
    pages: 7,
    duration: '03:00',
    published: '2004',
    img: require('../assets/img/covers/cover_an098.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an098-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an098-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an098-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an098-4.jpg'),
    previewImage05: require('../assets/img/preview_gallery/images/an098-5.jpg'),
  },
  {
    id: 'AN099',
    composer: 'Orbán György',
    title: 'Ó, Pán',
    price_printed: 3.2,
    price_digital: 2.2,
    orch: 'SSATBB',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 6,
    duration: '03:00',
    published: '1988',
    img: require('../assets/img/covers/cover_an099.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an099-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an099-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an099-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an099-4.jpg'),
  },
  {
    id: 'AN100',
    composer: 'Orbán György',
    title: 'Cantico di Frate Sole',
    price_printed: 8.4,
    price_digital: 5.8,
    orch: 'SMsATBB, piano',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 24,
    duration: '10:00',
    published: '2013',
    img: require('../assets/img/covers/cover_an100.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an100-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an100-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an100-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an100-4.jpg'),
    previewImage05: require('../assets/img/preview_gallery/images/an100-5.jpg'),
    previewImage06: require('../assets/img/preview_gallery/images/an100-6.jpg'),
  },
  {
    id: 'AN101',
    composer: 'Selmeczi György',
    title: 'Missa Sexta',
    price_printed: 7.8,
    price_digital: 5.4,
    orch: 'SATB, organ',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 22,
    duration: '15:00',
    published: '2011',
    img: require('../assets/img/covers/cover_an101.png'),
    video: 'https://www.youtube.com/embed/lUqvRmUZCeI?si=_2EiHLhhK5Ok7xw9',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an101-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an101-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an101-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an101-4.jpg'),
    previewImage05: require('../assets/img/preview_gallery/images/an101-5.jpg'),
    previewImage06: require('../assets/img/preview_gallery/images/an101-6.jpg'),
    previewImage07: require('../assets/img/preview_gallery/images/an101-7.jpg'),
    previewImage08: require('../assets/img/preview_gallery/images/an101-8.jpg'),
  },
  {
    id: 'AN102',
    composer: 'Orbán György',
    title: 'Salve Regina',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 4,
    duration: '1:30',
    published: '1995',
    img: require('../assets/img/covers/cover_an102.png'),
    video: 'https://www.youtube.com/embed/2M3_ks6ryzw?si=vdaM5x16P8-Z5IEW',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an102-1.png'),
    previewImage02: require('../assets/img/preview_gallery/images/an102-2.png'),
  },
  {
    id: 'AN103',
    composer: 'Kálmán Tamás',
    title: 'Túl a vizen',
    price_printed: 3.2,
    price_digital: 2.2,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 6,
    duration: '3:30',
    published: '2007',
    img: require('../assets/img/covers/cover_an103.png'),
    video: 'https://www.youtube.com/embed/JOljHs7CZXo?si=GZXDYPDKGHNAcHY0',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an103-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an103-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an103-3.jpg'),
  },
  {
    id: 'AN104',
    composer: 'Orbán György',
    title: 'Chiaro',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 4,
    duration: '2:30',
    published: '2006',
    img: require('../assets/img/covers/cover_an104.png'),
    video: 'https://www.youtube.com/embed/4relNLC04uc?si=Qi9o47ntOq5WE-2s',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an104-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an104-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an104-3.jpg'),
  },
  {
    id: 'AN105',
    composer: 'Orbán György',
    title: 'Caligo',
    price_printed: 2.9,
    price_digital: 2,
    orch: 'SSAATTBB',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 5,
    duration: '3:00',
    published: '2007',
    img: require('../assets/img/covers/cover_an105.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an105-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an105-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an105-3.jpg'),
  },
  {
    id: 'AN106',
    composer: 'Orbán György',
    title: 'Vále, vále',
    price_printed: 3.5,
    price_digital: 2.4,
    orch: 'SSATBB',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 7,
    duration: '6:00',
    published: '2007',
    img: require('../assets/img/covers/cover_an106.png'),
    video: 'https://www.youtube.com/embed/Zyz6jZSQ4Sw?si=GqemuWprl2SgwhJe',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an106-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an106-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an106-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an106-4.jpg'),
  },
  {
    id: 'AN107',
    composer: 'Orbán György',
    title: 'A fejedelem kertje',
    price_printed: 5.2,
    price_digital: 3.6,
    orch: 'SSATBB',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 13,
    duration: '3:00',
    published: '2010',
    img: require('../assets/img/covers/cover_an107.png'),
    video: 'https://www.youtube.com/embed/PqgkJ1WC3OU?si=8qW4feY-niqS7Jx1',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an107-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an107-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an107-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an107-4.jpg'),
  },
  {
    id: 'AN109',
    composer: 'Orbán György',
    title: 'Mass No. 6.',
    price_printed: 11.6,
    price_digital: 8,
    orch: 'SMsA',
    type: 'Female',
    skill: 'Medium',
    pages: 35,
    duration: '13:00',
    published: '2013',
    img: require('../assets/img/covers/cover_an109.png'),
    video: 'https://www.youtube.com/embed/cv8dSKvtAdo?si=F-FVXhZ13AqZNhSB',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an109-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an109-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an109-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an109-4.jpg'),
    previewImage05: require('../assets/img/preview_gallery/images/an109-5.jpg'),
    previewImage06: require('../assets/img/preview_gallery/images/an109-6.jpg'),
  },
  {
    id: 'AN110',
    composer: 'Orbán György',
    title: 'Ave Maria á 3',
    price_printed: 1.75,
    price_digital: 1.2,
    orch: 'SMsA',
    type: 'Female',
    skill: 'Medium',
    pages: 2,
    duration: '2:00',
    published: '2010',
    img: require('../assets/img/covers/cover_an110.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an110-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an110-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an110-3.jpg'),
  },
  {
    id: 'AN111',
    composer: 'Orbán György',
    title: 'Terge fletus á 3',
    price_printed: 2.9,
    price_digital: 2,
    orch: 'SMsA',
    type: 'Female',
    skill: 'Medium',
    pages: 5,
    duration: '3:00',
    published: '2010',
    img: require('../assets/img/covers/cover_an111.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an111-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an111-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an111-3.jpg'),
  },
  {
    id: 'AN112',
    composer: 'Orbán György',
    title: 'Zagreb unison',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'SATB, piano',
    type: 'Mixed',
    skill: 'Beginner',
    pages: 4,
    duration: '2:00',
    published: '2013',
    img: require('../assets/img/covers/cover_an112.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an112-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an112-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an112-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an112-4.jpg'),
  },
  {
    id: 'AN113',
    composer: 'Orbán György',
    title: `L' amato del mio cuore`,
    price_printed: 3.75,
    price_digital: 2.6,
    orch: 'SMsATBB, piano',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 8,
    duration: '3:00',
    published: '2014',
    img: require('../assets/img/covers/cover_an113.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an113-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an113-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an113-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an113-4.jpg'),
  },
  {
    id: 'AN114',
    composer: 'Orbán György',
    title: 'Mettimi',
    price_printed: 3.75,
    price_digital: 2.6,
    orch: 'SMsATBB, piano',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 8,
    duration: '3:00',
    published: '2014',
    img: require('../assets/img/covers/cover_an114.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an114-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an114-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an114-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an114-4.jpg'),
  },
  {
    id: 'AN115',
    composer: 'Orbán György',
    title: 'Dier Duid / Der Tod',
    price_printed: 5.5,
    price_digital: 3.8,
    orch: 'SMsATBB, piano',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 14,
    duration: '5:00',
    published: '2012',
    img: require('../assets/img/covers/cover_an115.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an115-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an115-2.jpg'),
  },
  {
    id: 'AN116',
    composer: 'Orbán György',
    title: 'Alma redemptoris mater (in D)',
    price_printed: 2.9,
    price_digital: 2,
    orch: 'SATB, piano',
    type: 'Mixed',
    skill: 'Advanced',
    pages: 5,
    duration: '4:30',
    published: '2010',
    img: require('../assets/img/covers/cover_an116.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an116-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an116-2.jpg'),
  },
  {
    id: 'AN117',
    composer: 'Orbán György',
    title: 'Medáliák könyve (AN061 - AN070)',
    price_printed: 13,
    price_digital: 9,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 40,
    duration: '16:00',
    published: '2013',
    img: require('../assets/img/covers/cover_an117.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an061-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an061-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an063-1.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an063-2.jpg'),
    previewImage05: require('../assets/img/preview_gallery/images/an064-1.jpg'),
  },
  {
    id: 'AN118',
    composer: 'Kálmán Tamás',
    title: 'Duna medley folk songs',
    price_printed: 3.2,
    price_digital: 2.2,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 6,
    duration: '3:30',
    published: '2020',
    img: require('../assets/img/covers/cover_an118.png'),
    video: 'https://www.youtube.com/embed/IIMdFrBWVOw?si=s2ekdvBAFs1-XIGp',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an118-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an118-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an118-3.jpg'),
  },
  {
    id: 'AN119',
    composer: 'Kálmán Tamás',
    title: 'Rózsát ültettem',
    price_printed: 3.2,
    price_digital: 2.2,
    orch: 'SSATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 6,
    duration: '3:30',
    published: '2020',
    img: require('../assets/img/covers/cover_an119.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an119-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an119-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an119-3.jpg'),
  },
  {
    id: 'AN120',
    composer: 'Orbán György',
    title: 'Latin Mixed Choirs 1.',
    price_printed: 15.9,
    price_digital: 10.9,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 110,
    duration: '-',
    published: '2021',
    img: require('../assets/img/covers/cover_an120.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an120-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an120-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an120-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an120-4.jpg'),
  },
  {
    id: 'AN121',
    composer: 'Guilio De Carlo',
    title: `L'aquilone`,
    price_printed: 6,
    price_digital: 4.2,
    orch: 'SA, piano',
    type: 'Female',
    skill: 'Medium',
    pages: 16,
    duration: '04:00',
    published: '2021',
    img: require('../assets/img/covers/cover_an121.png'),
    video: 'https://www.youtube.com/embed/mDTDMt1JOyU?si=Zfc-MKufIYxdwyPm',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an121-1.png'),
    previewImage02: require('../assets/img/preview_gallery/images/an121-2.png'),
    previewImage03: require('../assets/img/preview_gallery/images/an121-3.png'),
  },
  {
    id: 'AN122',
    composer: 'Orbán György',
    title: 'Latin Mixed Choirs 2.',
    price_printed: 15.9,
    price_digital: 10.9,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 121,
    duration: '-',
    published: '2021',
    img: require('../assets/img/covers/cover_an122.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an122-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an122-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an122-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an122-4.jpg'),
    previewImage05: require('../assets/img/preview_gallery/images/an122-5.jpg'),
  },
  {
    id: 'AN123',
    composer: 'Orbán György',
    title: 'Latin Female Choirs',
    price_printed: 15.9,
    price_digital: 10.9,
    orch: 'SSA',
    type: 'Female',
    skill: 'Medium',
    pages: 123,
    duration: '-',
    published: '2021',
    img: require('../assets/img/covers/cover_an123.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an123-1.jpg'),
    previewImage02: require('../assets/img/preview_gallery/images/an123-2.jpg'),
    previewImage03: require('../assets/img/preview_gallery/images/an123-3.jpg'),
    previewImage04: require('../assets/img/preview_gallery/images/an123-4.jpg'),
  },
  {
    id: 'AN124',
    composer: 'Orbán György',
    title: 'Stabat Mater (in D) - Score and instrumental parts',
    price_printed: 180.0,
    price_digital: 120.0,
    orch: 'Soprano and alto solo, mixed choir and orchestra',
    type: 'Mixed',
    skill: 'Medium',
    pages: 492,
    duration: '25:00',
    published: '2022',
    img: require('../assets/img/covers/cover_an124.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an124-1.png'),
    previewImage02: require('../assets/img/preview_gallery/images/an124-2.png'),
    previewImage03: require('../assets/img/preview_gallery/images/an124-3.png'),
    previewImage04: require('../assets/img/preview_gallery/images/an124-4.png'),
  },
  {
    id: 'AN125',
    composer: 'Orbán György',
    title: 'Stabat Mater (in D) - Vocal score',
    price_printed: 15.0,
    price_digital: 12.0,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 48,
    duration: '25:00',
    published: '2022',
    img: require('../assets/img/covers/cover_an125.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an125-1.png'),
    previewImage02: require('../assets/img/preview_gallery/images/an125-2.png'),
    previewImage03: require('../assets/img/preview_gallery/images/an125-3.png'),
    previewImage04: require('../assets/img/preview_gallery/images/an125-4.png'),
  },
  {
    id: 'AN126',
    composer: 'Battista Pradal',
    title: 'Seeing the stars',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 4,
    duration: '-',
    published: '2023',
    img: require('../assets/img/covers/cover_an126.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an126-1.png'),
    previewImage02: require('../assets/img/preview_gallery/images/an126-2.png'),
    previewImage03: require('../assets/img/preview_gallery/images/an126-3.png'),
  },
  {
    id: 'AN127',
    composer: 'Battista Pradal',
    title: 'O lux beata Trinitas',
    price_printed: 2.6,
    price_digital: 1.8,
    orch: 'SATB',
    type: 'Mixed',
    skill: 'Medium',
    pages: 4,
    duration: '-',
    published: '2023',
    img: require('../assets/img/covers/cover_an127.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an127-1.png'),
    previewImage02: require('../assets/img/preview_gallery/images/an127-2.png'),
    previewImage03: require('../assets/img/preview_gallery/images/an127-3.png'),
    previewImage04: require('../assets/img/preview_gallery/images/an127-4.png'),
  },
  {
    id: 'AN128',
    composer: 'Michele Josia',
    title: 'Regina Coeli',
    price_printed: 4.5,
    price_digital: 3,
    orch: 'For equal voices or mixed choir ad libitum with body percussion',
    type: 'Mixed',
    skill: 'Medium',
    pages: 10,
    duration: '-',
    published: '2023',
    img: require('../assets/img/covers/cover_an128.png'),
    video: 'https://www.youtube.com/embed/zU8eMyDsXtQ?si=XzMgBclf7FatQxLd',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an128-1.png'),
    previewImage02: require('../assets/img/preview_gallery/images/an128-2.png'),
    previewImage03: require('../assets/img/preview_gallery/images/an128-3.png'),
  },
  {
    id: 'AN129',
    composer: 'Orbán György',
    title: 'Peur Natus',
    price_printed: 4.3,
    price_digital: 2.8,
    orch: 'SATB, piano',
    type: 'Mixed',
    skill: 'Medium',
    pages: 9,
    duration: '-',
    published: '2023',
    img: require('../assets/img/covers/cover_an129.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an129-1.png'),
    previewImage02: require('../assets/img/preview_gallery/images/an129-2.png'),
    previewImage03: require('../assets/img/preview_gallery/images/an129-3.png'),
  },
  {
    id: 'AN130',
    composer: 'Orbán György',
    title: 'Gyermek születék',
    price_printed: 4.5,
    price_digital: 3,
    orch: 'SATB, piano',
    type: 'Mixed',
    skill: 'Medium',
    pages: 10,
    duration: '-',
    published: '2023',
    img: require('../assets/img/covers/cover_an130.png'),
    video: '',
    isAvailablePrinted: true,
    isAvailableDigital: true,
    previewImage01: require('../assets/img/preview_gallery/images/an130-1.png'),
    previewImage02: require('../assets/img/preview_gallery/images/an130-2.png'),
    previewImage03: require('../assets/img/preview_gallery/images/an130-3.png'),
  },
];
